import React, {useCallback} from 'react';
import {signOut} from 'commonreact';
import {LOGIN_ROUTE} from 'app/routes';
import {useNavigate} from 'react-router-dom';

export default function Logout(): JSX.Element {
  const navigate = useNavigate();

  const handleSignOut = useCallback(async () => {
    await signOut();
    navigate(LOGIN_ROUTE, {replace: true});
  }, [navigate]);

  return (
    <button
      onClick={handleSignOut}
      className="text-sm text-truffle font-normal"
    >
      Log Out
    </button>
  );
}
