import React from 'react';
import {InstructionSectionInput} from '../../services/graphql/apolloTypes';
import {
  errorStyle,
  inputBoxStyle,
  sectionHeaderInputBoxStyle,
} from 'features/utilities/styles';
import {UploadErrors} from 'features/utilities/errors';

type InstructionSectionProps = {
  sectionIndex: number;
  instructionSection: InstructionSectionInput;
  handleInstructionSectionNameChange: (index: number, value: string) => void;
  handleInstructionChange: (
    sectionIndex: number,
    instructionIndex: number,
    value: string
  ) => void;
  deleteInstructionSection: (sectionIndex: number) => void;
  deleteInstruction: (sectionIndex: number, instructionIndex: number) => void;
  addInstructionToStartOfSection: (sectionIndex: number) => void;
  uploadErrors: UploadErrors;
};

const InstructionSection: React.FC<InstructionSectionProps> = ({
  sectionIndex,
  instructionSection,
  handleInstructionSectionNameChange,
  handleInstructionChange,
  deleteInstructionSection,
  deleteInstruction,
  addInstructionToStartOfSection,
  uploadErrors,
}) => {
  return (
    <div key={`instruction-section-${sectionIndex}`}>
      <div className="flex justify-between items-center space-x-2 mb-3">
        {/* <img src="/dragicon.svg" alt="drag icon" /> */}
        <div className="flex rounded-lg shadow-sm w-full">
          <span className="inline-flex items-center rounded-l-lg border border-r-0 border-taro bg-salt px-3 text-charcoal font-normal sm:text-sm">
            Section
          </span>
          <input
            id={`instruction-section-name-${sectionIndex}`}
            type="text"
            value={instructionSection.name}
            onChange={e =>
              handleInstructionSectionNameChange(sectionIndex, e.target.value)
            }
            className={`${sectionHeaderInputBoxStyle} ${
              uploadErrors[`instructionSection_${sectionIndex}_`] && errorStyle
            }`}
            placeholder="Use sections to organize long direction lists. Optional."
          ></input>
        </div>
        <button
          type="button"
          onClick={() => addInstructionToStartOfSection(sectionIndex)}
          className="text-ash font-bold py-1 rounded-lg"
          title="Add direction"
        >
          <img src="/addrowicon.svg" alt="add row icon" />
        </button>
        <button
          type="button"
          onClick={() => deleteInstructionSection(sectionIndex)}
          className="text-ash font-bold py-1 pr-2 rounded"
          title="Delete section"
        >
          <img src="/deleteicon.svg" alt="delete icon" />
        </button>
      </div>
      {instructionSection.instructions.map((instruction, instructionIndex) => (
        <div key={`instruction-${sectionIndex}-${instructionIndex}`}>
          <div className="flex justify-between items-center mb-3 space-x-2">
            {/* <img src="/dragicon.svg" alt="drag icon" /> */}
            <div className="w-3">
              <label>{instructionIndex + 1}</label>
            </div>
            <div className="flex w-full">
              <input
                id={`instruction-${sectionIndex}-${instructionIndex}`}
                type="text"
                value={instruction}
                onChange={e =>
                  handleInstructionChange(
                    sectionIndex,
                    instructionIndex,
                    e.target.value
                  )
                }
                className={`${inputBoxStyle} block w-full min-w-0 flex-1 ${
                  uploadErrors[
                    `instruction_${sectionIndex}_${instructionIndex}`
                  ] && errorStyle
                }`}
                placeholder="Direction description"
              ></input>
            </div>
            <button
              type="button"
              onClick={() => deleteInstruction(sectionIndex, instructionIndex)}
              className="text-ash font-bold py-1 px-2 rounded"
              title="Delete direction"
            >
              <img src="/deleteicon.svg" alt="delete icon" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InstructionSection;
