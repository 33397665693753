import React from 'react';

function FlavrsHeader(): JSX.Element {
  return (
    <>
      <img
        src="/flavrs.svg"
        alt="flavrs logo"
        className="w-20 h-5 object-scale-down lg:object-cover mt-16"
      />
      <div className="mt-12 text-center font-simula text-charcoal font-normal text-xl md:text-5xl lg:text-6xl">
        Creator Kitchen
      </div>
    </>
  );
}

export default FlavrsHeader;
