import React, {useContext, useState} from 'react';
import Select, {SingleValue, MultiValue, ActionMeta} from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  ParseFreeTextForTagsDocument,
  RecipeTagEntryInput,
  RecipeTagType,
  TernaryState,
} from '../../services/graphql/apolloTypes';
import {TagsContext} from '../../components/autocomplete/AutocompleteProviders';
import {
  multiSelectStyle,
  headerStyle,
  errorMessageStyle,
} from 'features/utilities/styles';
import {refreshTags, withFixedOrdering} from './converters';
import {UploadErrors} from 'features/utilities/errors';
import AlertBanner, {AlertBannerType} from 'features/utilities/AlertBanner';
import {useLazyQuery} from '@apollo/client';
import {
  ArrowPathIcon,
  ExclamationCircleIcon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import {ParseFreeTextForTagsQueryTag} from 'services/graphql/apolloOperationTypes';

interface TagsSectionProps {
  title: string;
  recipeTagEntries: RecipeTagEntryInput[];
  setRecipeTagEntries: (recipeTagEntries: RecipeTagEntryInput[]) => void;
  uploadErrors: UploadErrors;
  ingredientFreeText: string;
  instructionFreeText: string;
}
const animatedComponents = makeAnimated();

const TagsSection: React.FC<TagsSectionProps> = ({
  title,
  recipeTagEntries,
  setRecipeTagEntries,
  uploadErrors,
  ingredientFreeText,
  instructionFreeText,
}) => {
  const {items} = useContext(TagsContext);

  enum AutoFillState {
    NEW,
    PENDING,
    RESOLVED,
    ERROR,
  }
  const [autoFillState, setAutoFillState] = useState(AutoFillState.NEW);
  const [parseFreeTextForTags] = useLazyQuery(ParseFreeTextForTagsDocument);

  const enableEdit = !!title && !!ingredientFreeText && !!instructionFreeText;
  const enableManualEdit =
    enableEdit && autoFillState !== AutoFillState.PENDING;

  // Process openai results.
  const processParsedResult = (parsedTags: ParseFreeTextForTagsQueryTag[]) => {
    const recipeTagEntries: RecipeTagEntryInput[] = parsedTags.map(tag => ({
      tagId: tag.id,
      tagValue: tag.value,
    }));
    const updatedTags = refreshTags(recipeTagEntries, items);
    setRecipeTagEntries(withFixedOrdering(updatedTags));
  };

  const runAutoFill = async () => {
    setAutoFillState(AutoFillState.PENDING);
    const {error, data} = await parseFreeTextForTags({
      variables: {
        textInput: {
          title,
          ingredients: ingredientFreeText,
          instructions: instructionFreeText,
        },
      },
    });
    if (error || !data?.parseFreeTextForTags) {
      setAutoFillState(AutoFillState.ERROR);
    } else {
      processParsedResult(data.parseFreeTextForTags.entries);
      setAutoFillState(AutoFillState.RESOLVED);
    }
  };

  // filter tag options by types
  const formatAsOptions = (tags: typeof items) =>
    tags.map(tag => ({
      value: tag.id,
      label:
        // Indent tags if they have parents.
        tag.parents.length > 0 ? '    ' + tag.displayName : tag.displayName,
    }));

  const getSelectedValues = (type: RecipeTagType) => {
    return recipeTagEntries
      .filter(entry =>
        items.some(item => item.id === entry.tagId && item.types.includes(type))
      )
      .map(entry => ({
        value: entry.tagId,
        label:
          items.find(item => item.id === entry.tagId)?.displayName ||
          entry.tagId,
      }));
  };

  const handleTagChange = (
    _:
      | SingleValue<{value: string; label: string}>
      | MultiValue<{value: string; label: string}>,
    action: ActionMeta<{value: string; label: string}>
  ) => {
    setAutoFillState(AutoFillState.NEW);
    // if removed tag, remove from recipeTagEntries
    if (action.action === 'remove-value') {
      const removedTagId = action.removedValue.value;
      const newRecipeTagEntries = recipeTagEntries.filter(
        entry => entry.tagId !== removedTagId
      );
      setRecipeTagEntries(newRecipeTagEntries);
    }
    // if added tag, add to recipeTagEntries
    if (action.action === 'select-option' && action.option) {
      const addedTagId = action.option.value;
      const newRecipeTagEntries = [
        ...recipeTagEntries,
        {
          tagId: addedTagId,
          tagValue: items.find(item => item.id === addedTagId)?.value || '',
        },
      ];
      setRecipeTagEntries(withFixedOrdering(newRecipeTagEntries));
    }
  };

  const handleAllergyTagChange = (
    value: string,
    ternaryState: TernaryState
  ) => {
    // get tagId
    const tagId = items.find(
      item =>
        item.value === value &&
        item.types.includes(RecipeTagType.Allergy) &&
        item.ternaryState === ternaryState
    )?.id;
    if (!tagId) throw new Error('Tag not found');

    // decide whether to add or remove the tag based on current state
    const currentAllergyTag = recipeTagEntries.find(
      entry => entry.tagValue === value
    );
    const alreadyHasTag = currentAllergyTag?.tagId === tagId;
    if (!alreadyHasTag) {
      // replace current allergy tag
      const newRecipeTagEntries = recipeTagEntries.filter(
        entry => entry.tagValue !== value
      );
      setRecipeTagEntries(
        withFixedOrdering([...newRecipeTagEntries, {tagId, tagValue: value}])
      );
    }
  };

  // Styles
  const tagHeaderClass = 'text-charcoal text-sm font-medium';
  const defaultAllergyButtonClass = 'w-1/3 text-center font-medium p-2';
  const getAllergyDisplayName = (tagValue: string) => {
    if (tagValue === 'peanut') {
      return 'Peanuts';
    } else if (tagValue === 'treenut') {
      return 'Tree Nuts';
    } else if (tagValue === 'sugar') {
      return 'Added Sugar';
    } else if (['egg', 'mushroom'].includes(tagValue)) {
      return tagValue.charAt(0).toUpperCase() + tagValue.slice(1) + 's';
    }
    return tagValue.charAt(0).toUpperCase() + tagValue.slice(1);
  };
  const getAllergyTogglerButtonClass = (
    tagValue: string,
    ternaryState: TernaryState,
    position: 'left' | 'middle' | 'right'
  ) => {
    const currentAllergyTag = recipeTagEntries.find(
      entry =>
        entry.tagValue === tagValue &&
        items.find(item => item.id === entry.tagId)?.ternaryState ===
          ternaryState
    );
    const isCurrentlyTagged = !!currentAllergyTag;
    const isUnknown = ternaryState === TernaryState.Unknown;
    let buttonClass = isCurrentlyTagged
      ? `${defaultAllergyButtonClass} text-charcoal ${
          isUnknown ? 'bg-salt' : 'bg-basiltint'
        }`
      : `${defaultAllergyButtonClass} text-ash`;

    if (position === 'left') {
      buttonClass += ' rounded-l-sm border-r border-ash';
    } else if (position === 'right') {
      buttonClass += ' rounded-r-sm border-l border-ash';
    }
    return buttonClass;
  };

  // Components
  const AllergyToggler: React.FC<{tagValue: string; disabled: boolean}> = ({
    tagValue,
    disabled,
  }) => (
    <div className="flex space-x-4 md:space-x-10 justify-center">
      <div className="flex justify-end items-center w-1/6">
        <div className="text-light font-light">
          {getAllergyDisplayName(tagValue)}
        </div>
      </div>
      <div className="flex justify-center w-5/6 md:w-2/3 rounded border border-ash">
        <button
          type="button"
          className={getAllergyTogglerButtonClass(
            tagValue,
            TernaryState.Present,
            'left'
          )}
          disabled={disabled}
          onClick={() => handleAllergyTagChange(tagValue, TernaryState.Present)}
        >
          Contains {getAllergyDisplayName(tagValue)}
        </button>
        <button
          type="button"
          className={getAllergyTogglerButtonClass(
            tagValue,
            TernaryState.Unknown,
            'middle'
          )}
          disabled={disabled}
          onClick={() => handleAllergyTagChange(tagValue, TernaryState.Unknown)}
        >
          Unknown
        </button>
        <button
          type="button"
          className={getAllergyTogglerButtonClass(
            tagValue,
            TernaryState.Absent,
            'right'
          )}
          disabled={disabled}
          onClick={() => handleAllergyTagChange(tagValue, TernaryState.Absent)}
        >
          {tagValue === 'sugar'
            ? `No ${getAllergyDisplayName(tagValue)}`
            : `${getAllergyDisplayName(tagValue)}-free`}
        </button>
      </div>
    </div>
  );

  // Style
  const additionalTailwindMultiSelectStyle = 'my-2 rounded-lg shadow-sm';

  const getMultiSelectComponent = (
    tagHeader: string,
    tagType: RecipeTagType
  ) => {
    const options = formatAsOptions(
      items
        .filter(tag => {
          const hasTagType = tag.types.includes(tagType);
          if (!hasTagType) return false;
          if (tagType === RecipeTagType.Diet) {
            return tag.ternaryState === TernaryState.Present;
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          // Sort tags in the following order:
          // 1. Sort by the parent tag label (displayName);
          // 2. If parent tag labels are the same, both tags have the same parent.
          //    1) Order the parent tag first.
          //    2) If both tags are not the parent tag, sort by their displayName.

          // A tag might have multiple parents. Place the tag under its first parent.
          const aParent =
            a.parents.length > 0 ? a.parents[0].displayName : a.displayName;
          const bParent =
            b.parents.length > 0 ? b.parents[0].displayName : b.displayName;
          if (aParent < bParent) {
            return -1;
          } else if (aParent > bParent) {
            return 1;
          } else if (a.displayName === aParent) {
            return -1;
          } else if (b.displayName === bParent) {
            return 1;
          } else if (a.displayName < b.displayName) {
            return -1;
          } else if (a.displayName > b.displayName) {
            return 1;
          } else {
            return 0;
          }
        })
    );

    return (
      <>
        <div className={tagHeaderClass}>{tagHeader}</div>
        <Select
          className={additionalTailwindMultiSelectStyle}
          id={`${tagType}-tags`}
          placeholder="Select"
          components={animatedComponents}
          value={getSelectedValues(tagType)}
          options={options}
          isMulti
          isDisabled={!enableManualEdit}
          onChange={handleTagChange}
          styles={multiSelectStyle}
          isClearable={false}
          closeMenuOnSelect={false}
        />
      </>
    );
  };

  const getAlertBanner = () => {
    switch (autoFillState) {
      case AutoFillState.NEW:
        return (
          <AlertBanner
            type={AlertBannerType.NEUTRAL}
            icon={<SparklesIcon className="w-5 h-5" />}
            message="See suggestions based on your recipe. Title, ingredients, and directions required."
            actionText="See suggestions"
            handleActionClick={enableEdit ? runAutoFill : undefined}
          />
        );
      case AutoFillState.PENDING:
        return (
          <AlertBanner
            type={AlertBannerType.NEUTRAL}
            icon={<ArrowPathIcon className="w-5 h-5" />}
            message="We are working on generating suggested attributes based on your recipe info..."
          />
        );
      case AutoFillState.RESOLVED:
        return (
          <AlertBanner
            type={AlertBannerType.NEUTRAL}
            icon={<SparklesIcon className="w-5 h-5" />}
            message="Please review the following attributes to ensure their accuracy."
            actionText="Re-generate suggestions"
            handleActionClick={enableEdit ? runAutoFill : undefined}
          />
        );
      case AutoFillState.ERROR:
        return (
          <AlertBanner
            type={AlertBannerType.ERROR}
            icon={<ExclamationCircleIcon className="w-5 h-5" />}
            message="Attribute suggestion is not working right now, please proceed with manual input."
          />
        );
    }
  };

  const allergyList = [
    'fish',
    'shellfish',
    'grain',
    'wheat',
    'gluten',
    'dairy',
    'egg',
    'peanut',
    'soy',
    'sesame',
    'mushroom',
    'sugar',
  ];

  return (
    <div>
      <div className={!enableEdit ? 'opacity-40' : ''}>
        <h3 className={headerStyle}>Attributes</h3>
        {uploadErrors.tags && (
          <p className={errorMessageStyle}>{uploadErrors.tags}</p>
        )}
        <p className="text-sm text-truffle font-normal">
          Choose attributes that accurately reflect this recipe. These will be
          used as tags to help users understand and find your recipe on flavrs
          app
        </p>

        <div className="mt-4">{getAlertBanner()}</div>
      </div>

      <div className={`divide-y ${!enableManualEdit ? 'opacity-40' : ''}`}>
        <div className="pb-5">
          <div className="flex space-x-6">
            <div className="pt-5 w-1/2">
              {getMultiSelectComponent('Food type', RecipeTagType.Food)}
            </div>
            <div className="pt-5 w-1/2">
              {getMultiSelectComponent('Cuisine', RecipeTagType.Cuisine)}
            </div>
          </div>
          <div className="flex space-x-6">
            <div className="pt-5 w-1/2">
              {getMultiSelectComponent('Dietary type', RecipeTagType.Diet)}
            </div>
            <div className="pt-5 w-1/2">
              {getMultiSelectComponent('Occasion', RecipeTagType.Occasion)}
            </div>
          </div>
          <div className="flex space-x-6">
            <div className="pt-5 w-1/2">
              {getMultiSelectComponent('Skill level', RecipeTagType.Skill)}
            </div>
            <div className="pt-5 w-1/2">
              {getMultiSelectComponent('Special equipment', RecipeTagType.Tech)}
            </div>
          </div>
        </div>
        <div className="pt-5">
          <h3 className={headerStyle}>Allergy details</h3>
          <p className="text-sm text-truffle font-normal">
            Indicate whether this recipe either “contains” or is “free” of the
            following allergens. Please leave it as “unknown” if you’re not
            sure.
          </p>
          <ul className="flex flex-col space-y-2 pt-5">
            {allergyList.map(entry => (
              <li key={entry}>
                <AllergyToggler tagValue={entry} disabled={!enableManualEdit} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TagsSection;
