import React, {useContext} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import {AuthContext, AuthState} from '../services/auth/AuthProvider';
import {TagsProvider} from '../components/autocomplete/AutocompleteProviders';
import Login from 'features/auth/Login';
import {
  MAIN_ROUTE,
  LOGIN_ROUTE,
  VIDEOS_ROUTE,
  PROFILE_ROUTE,
  UPLOAD_ROUTE,
  SIGNUP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
} from './routes';
import UploadRecipe from 'features/recipe/UploadRecipe';
import Navbar from 'features/nav/Navbar';
import VideoLibrary from 'features/videos/VideoLibrary';
import Profile from 'features/profile/Profile';
import SignUp from 'features/auth/SignUp';
import ForgotPassword from 'features/auth/ForgotPassword';

function RequireAuth({children}: {children: JSX.Element}): JSX.Element {
  const {state} = useContext(AuthContext);
  const location = useLocation();

  switch (state) {
    case AuthState.LOADING:
      return (
        <div style={{minHeight: '100vh', width: '100%'}}>
          {/* TODO: Add Tailwind loading component */}
          <p>Loading...</p>
        </div>
      );

    case AuthState.AUTHENTICATED:
      return (
        <>
          {children}
          <Outlet />
        </>
      );
    case AuthState.UNAUTHENTICATED:
    default:
      return <Navigate to={LOGIN_ROUTE} state={{from: location}} replace />;
  }
}

function Future404(): JSX.Element {
  return (
    <main style={{padding: '1rem'}}>
      <p>Future 404 page!</p>
    </main>
  );
}

const UploadRecipeWrapper = () => (
  <TagsProvider>
    <UploadRecipe />
  </TagsProvider>
);

function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOGIN_ROUTE} element={<Login />} />
        <Route path={SIGNUP_ROUTE} element={<SignUp />} />
        <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
        <Route
          path={MAIN_ROUTE}
          element={
            <RequireAuth>
              <Navbar />
            </RequireAuth>
          }
        >
          <Route index element={<Navigate to={VIDEOS_ROUTE} replace />} />
          <Route path={VIDEOS_ROUTE} element={<VideoLibrary />} />
          <Route path={PROFILE_ROUTE} element={<Profile />} />
          <Route
            path={`${UPLOAD_ROUTE}/:id?`}
            element={<UploadRecipeWrapper />}
          />
        </Route>
        <Route path="*" element={<Future404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
