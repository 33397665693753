import React, {useState, useContext, useEffect} from 'react';
import {AuthError} from 'firebase/auth';
import {authErrorMessages} from '../utilities/errors';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  deleteUser,
  fetchSignInMethodsForEmail,
  signOut,
} from 'commonreact';
import {
  SendVerificationEmailDocument,
  SignUpCreatorDocument,
  CreatorInfo,
  GetWhitelistedCreatorDocument,
} from '../../services/graphql/apolloTypes';
import {AuthContext, AuthState} from '../../services/auth/AuthProvider';
import {User} from 'firebase/auth';
import {useMutation, useLazyQuery} from '@apollo/client';
import {LOGIN_ROUTE, MAIN_ROUTE} from 'app/routes';
import * as Yup from 'yup';
import {Formik, Form, useFormikContext} from 'formik';
import {Navigate} from 'react-router-dom';
import {EyeIcon, EyeSlashIcon, EnvelopeIcon} from '@heroicons/react/24/solid';
import FlavrsHeader from './FlavrsHeader';
import {
  textInputBoxStyle,
  buttonStyle,
  errorStyle,
  errorMessageStyle,
} from '../utilities/styles';
import ErrorPanel from '../utilities/ErrorPanel';
import {EmailCheckPrompt} from './EmailCheckPrompt';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Your password must be at least 6 characters')
    .required('Password is required'),
});

function SignUp(): JSX.Element {
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [hasInput, setHasInput] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [sendVerificationEmail] = useMutation(SendVerificationEmailDocument);
  const [signUpCreator] = useMutation(SignUpCreatorDocument);
  const [getWhitelistedCreator] = useLazyQuery(GetWhitelistedCreatorDocument);

  const inputBoxWidth = 'w-2/3 md:w-7/12';
  const iconStyle = 'h-5 w-5 fill-shell';

  interface SignupInputs {
    email: string;
    password: string;
  }

  const rollback = async (user: User) => {
    if (user) {
      await deleteUser(user);
    }
  };

  const handleWhitelistedCreator = async (
    whitelistedCreator: CreatorInfo,
    email: string,
    password: string
  ) => {
    let user: User;
    try {
      // Check if a Firebase user already exists for the email
      const signInMethods = await fetchSignInMethodsForEmail(email);
      if (signInMethods.length > 0) {
        // Sign in the existing user
        user = await signInWithEmailAndPassword(email, password);
      } else {
        // Create a new Firebase user
        user = await createUserWithEmailAndPassword(email, password);
      }
    } catch (e) {
      const errorCode = (e as AuthError)?.code;
      const msg = authErrorMessages[errorCode] || errorCode;
      throw new Error(msg);
    }

    try {
      // Create/update a profile in the database
      await signUpCreator({
        variables: {
          newCreatorInfo: whitelistedCreator,
        },
      });
    } catch (err) {
      await rollback(user);
      const msg =
        (err instanceof Error
          ? err.message
          : 'Failed to create a creator profile.') +
        ' Please try again or reach out to Flavrs for help.';
      throw new Error(msg);
    }

    // send a verification email if not verified
    try {
      if (!user.emailVerified) {
        // Wait 5s for the Braze profile to be propagated before
        // using it to send verification email.
        await new Promise(resolve => setTimeout(resolve, 5000));

        await sendVerificationEmail();
        setEmailSent(true);
        setEmailSentTo(email);
      }
    } catch (err) {
      await rollback(user);
      const msg =
        (err instanceof Error
          ? err.message
          : 'Failed to send the verification email.') +
        ' Please try again or reach out to Flavrs for help.';
      throw new Error(msg);
    }
  };

  const handleSubmit = async (
    values: SignupInputs,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setError('');
    setSubmitting(true);

    const {email, password} = values;

    // Check if creator is in whitelist and get their info
    try {
      // Check if creator is in whitelist and get their info
      const {data} = await getWhitelistedCreator({
        variables: {
          email,
        },
      });

      if (!data?.getWhitelistedCreator) {
        throw new Error(
          `${email} is not a whitelisted email. If you believe this is the correct email, please reach out to Flavrs for help.`
        );
      } else {
        const whitelistedCreator: CreatorInfo = {
          email,
          displayName: data.getWhitelistedCreator.displayName,
          username: data.getWhitelistedCreator.username,
        };

        await handleWhitelistedCreator(whitelistedCreator, email, password);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError(
          'Error creating a new creator account. Please try again or reach out to Flavrs for help.'
        );
      }
    } finally {
      signOut();
      setSubmitting(false);
    }
  };

  const authData = useContext(AuthContext);
  if (authData.state === AuthState.AUTHENTICATED) {
    return <Navigate to={MAIN_ROUTE} replace />;
  }

  if (emailSent) {
    return <EmailCheckPrompt email={emailSentTo || ''} />;
  } else {
    const FormObserver: React.FC = () => {
      const {values} = useFormikContext<SignupInputs>();
      useEffect(() => {
        setHasInput(!!values.email || !!values.password);
        if (inputChanged) {
          setError('');
          setInputChanged(false);
        }
      }, [inputChanged]);
      return null;
    };

    return (
      <div className="w-full min-h-screen bg-cover bg-marble-texture">
        <div className="w-full h-20"></div>
        <div className="flex flex-row w-full justify-center">
          <div className="w-4/5 md:2/3 lg:w-1/2 rounded-lg bg-white">
            <Formik
              initialValues={{email: '', password: ''}}
              validationSchema={validationSchema}
              onSubmit={async (values, {setSubmitting}) => {
                await handleSubmit(values, setSubmitting);
              }}
            >
              {({errors, touched, handleChange, isSubmitting, values}) => (
                <Form className="flex flex-col justify-start items-center">
                  <FormObserver />
                  <FlavrsHeader />
                  <p className="w-3/4 md:w-2/3 text-charcoal text-center font-medium text-lg mt-9">
                    Enter your email address and a secure password
                  </p>
                  <div className={`${inputBoxWidth} relative mt-9`}>
                    <input
                      id="email"
                      type="email"
                      value={values.email}
                      placeholder="Email address"
                      onChange={e => {
                        handleChange(e);
                        setInputChanged(true);
                      }}
                      className={`${textInputBoxStyle(error)} ${
                        errors.email && errorStyle
                      }`}
                    />
                    <div className="absolute inset-y-0 left-4 flex items-center pl-0.5">
                      <EnvelopeIcon className={iconStyle} />
                    </div>
                  </div>
                  {errors.email && touched.email && (
                    <p className={errorMessageStyle}>{errors.email}</p>
                  )}
                  <div className={`${inputBoxWidth} relative mt-4`}>
                    <input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      placeholder="Password"
                      onChange={e => {
                        handleChange(e);
                        setInputChanged(true);
                      }}
                      className={`${textInputBoxStyle(error)} ${
                        errors.password && errorStyle
                      }`}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 left-4 flex items-center pl-0.5 cursor-pointer"
                    >
                      {showPassword ? (
                        <EyeSlashIcon className={iconStyle} />
                      ) : (
                        <EyeIcon className={iconStyle} />
                      )}
                    </div>
                  </div>
                  {errors.password && touched.password && (
                    <p className={errorMessageStyle}>{errors.password}</p>
                  )}
                  <button
                    type="submit"
                    disabled={!hasInput || isSubmitting}
                    className={`${inputBoxWidth} h-12 mt-4 ${
                      hasInput ? 'bg-charcoal text-white' : 'bg-salt text-light'
                    } ${buttonStyle}`}
                  >
                    {isSubmitting ? 'Signing up...' : 'Sign up'}
                  </button>
                  <div className="w-full h-6"></div>
                  <p>
                    <a
                      href={LOGIN_ROUTE}
                      className="text-truffle text-center font-medium text-base"
                    >
                      Back
                    </a>
                  </p>
                  <div className="w-full h-5"></div>
                  <ErrorPanel error={error}></ErrorPanel>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
