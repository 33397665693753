import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {UPLOAD_ROUTE} from 'app/routes';
import UploadedRecipes from './UploadedRecipes';
import {NetworkStatus, useQuery} from '@apollo/client';
import {useDebounce} from 'use-debounce';

import {GetUploadedRecipesDocument} from '../../services/graphql/apolloTypes';
import {AuthContext} from '../../services/auth/AuthProvider';
import {MagnifyingGlassIcon} from '@heroicons/react/24/solid';

const LIMIT = 20;

function VideoLibrary(): JSX.Element | null {
  // Get creator's uploaded recipes (with search query if applicable)
  // TODO: Add pagination
  const searchConditions = {
    limit: LIMIT,
  };
  const [inputValue, setInputValue] = useState('');
  const [searchQuery] = useDebounce(inputValue, 500);
  const {loading, error, data, fetchMore, networkStatus} = useQuery(
    GetUploadedRecipesDocument,
    {
      variables: {
        query: searchQuery || '',
        searchConditions,
      },
    }
  );
  const {user} = useContext(AuthContext);
  if (!user) return null;
  if (loading) return <div>Loading...</div>;
  if (error || !data) return <div>Error: {error?.message}</div>;
  const {totalCount, hasMore, cursor, uploadedRecipes} =
    data.getUploadedRecipes;

  const recipes = uploadedRecipes || [];
  const handleMore = async (): Promise<void> => {
    if (hasMore && !loading && networkStatus !== NetworkStatus.fetchMore) {
      await fetchMore({
        variables: {
          searchConditions: {
            limit: LIMIT,
            cursor,
          },
        },
      });
    }
  };
  const leftPageMarginWidth = 'ml-1/20 lg:ml-1/10';
  const rightPageMarginWidth = 'mr-1/20 lg:mr-1/10';
  return (
    <div className="w-full bg-salt pb-16 min-h-screen">
      <div className="flex flex-row justify-between items-center w-full py-4">
        <div
          className={`font-bold ${leftPageMarginWidth} text-base md:text-lg lg:text-3xl text-charcoal`}
        >
          Video Library
        </div>
        <Link to={UPLOAD_ROUTE} className={rightPageMarginWidth}>
          <button
            className={
              'w-36 h-12 bg-charcoal shadow rounded-md text-white text-base font-medium'
            }
          >
            + New Video
          </button>
        </Link>
      </div>

      <div className="flex flex-row justify-between items-center w-full py-4">
        <div className={`relative font-bold ${leftPageMarginWidth} text-base`}>
          <MagnifyingGlassIcon className="text-shell pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3" />
          <input
            type="text"
            value={inputValue}
            onChange={async e => {
              setInputValue(e.target.value);
            }}
            placeholder="Search video"
            autoFocus
            className="shadow border border-shell rounded-lg p-2 placeholder-shell font-normal text-charcoal pl-12 pr-12 sm:pr-32 md:pr-60"
          />
        </div>
        <div className={`text-truffle ${rightPageMarginWidth}`}>
          {totalCount} {totalCount === 1 ? 'video' : 'videos'}
        </div>
      </div>

      {recipes.length === 0 ? (
        <div
          className={`${leftPageMarginWidth} ${rightPageMarginWidth} px-5 border border-taro rounded-lg shadow text-center py-20`}
        >
          <div className="text-lg font-semibold">Hi {user.displayName}!</div>
          <div className="pt-10 text-truffle font-semibold">
            Your videos will show up here once you start uploading.
          </div>
          <div className="pt-1 text-sm font-normal text-shell">
            <Link to={UPLOAD_ROUTE}>
              <span className="text-persimmon">Add a new video</span>
            </Link>{' '}
            to get started
          </div>
        </div>
      ) : (
        <UploadedRecipes
          recipes={recipes}
          hasMore={hasMore && !loading}
          handleMore={handleMore}
        />
      )}
    </div>
  );
}

export default VideoLibrary;
