import {useMutation} from '@apollo/client';
import {
  UploadRecipeInput,
  CreateVideoUploadUrlDocument,
  GetUploadedRecipesDocument,
  UploadRecipeDocument,
  UploadedRecipeStatus,
} from '../../services/graphql/apolloTypes';
import {
  parseToIngredientSectionInput,
  parseToTotalTimeInput,
} from 'features/recipe/converters';
import {
  ImageInput,
  SimpleInput,
  UploadRecipeVideoForm,
  VideoInput,
} from 'features/recipe/upload-recipe-types';
import {uploadVideoFile} from 'commonreact';

const LIMIT = 20;
/**
 * Provides a custom hook for upserting RecipeVideos, along helper functions.
 * @param setSubmittingMessage A callback for customizing the submission message
 */
export function useRecipeVideoUpload(
  setSubmittingMessage: (submittingMessage: string) => void
) {
  const [createVideoUploadURL] = useMutation(CreateVideoUploadUrlDocument);
  const [upsertRecipeVideo] = useMutation(UploadRecipeDocument);

  /**
   * This method performs 2 auxiliary backend calls in order to transform a
   * restaurant video file into a GCP path that can then be used in the video upload
   * graphql endpoint:
   * 1. Fetches through apiserver a signed GCP url to which a video will be sent
   * 2. Upload the video file to that signed GCP url directly via HTTP PUT
   */
  const uploadVideoToGcp = async (videoFile: File) => {
    // 1. We need to get a signed URL
    const response = await createVideoUploadURL({
      variables: {
        contentType: videoFile.type,
      },
    });
    if (!response.data) {
      throw new Error('Could not obtain video upload URL');
    }
    const {uploadUrl, gcpPath} = response.data.createVideoUploadURL;

    // 2. We need to upload the video filed to the signed URL
    // We use the callback to update with the percentage upload when available.
    await uploadVideoFile(videoFile, uploadUrl, number => {
      if (number) {
        setSubmittingMessage(`Uploading video: ${number}%...`);
      } else {
        setSubmittingMessage('Uploading video, please wait...');
      }
    });

    return gcpPath;
  };

  /**
   * Transform the UI input (UploadRecipeVideoForm)
   * into the API input (UploadRecipeInput)
   * for GQL mutation uploadRecipe
   */
  const processAndUpsertRecipeVideo = async (
    uploadRecipeVideoForm: UploadRecipeVideoForm,
    targetStatus: UploadedRecipeStatus
  ) => {
    // 1. Simple fields.
    const simpleInput: SimpleInput = {
      // id/status fields
      status: targetStatus,
      id: uploadRecipeVideoForm.id,

      // directly editable fields
      title: uploadRecipeVideoForm.title,
      description: uploadRecipeVideoForm.description || undefined,
      yieldServings: parseInt(uploadRecipeVideoForm.yieldServings),
      prepTimeMins: parseInt(uploadRecipeVideoForm.prepTimeMins),
      cookTimeMins: parseInt(uploadRecipeVideoForm.cookTimeMins),
      totalTimeMins: parseToTotalTimeInput(
        uploadRecipeVideoForm.prepTimeMins,
        uploadRecipeVideoForm.cookTimeMins
      ),

      ingredientSections: parseToIngredientSectionInput(
        uploadRecipeVideoForm.ingredientSections,
        targetStatus
      ).ingredientSectionsInput, // Strip uncessary fields
      instructionSections: uploadRecipeVideoForm.instructionSections,
      recipeTagEntries: uploadRecipeVideoForm.recipeTagEntries,
    };

    // 2. Image fields: either we have an image File, and we upload it,
    // or we pass old values.
    // TODO: support image clearing.
    const imageInput: ImageInput = uploadRecipeVideoForm.imageFile
      ? {imageFile: uploadRecipeVideoForm.imageFile}
      : {
          // These could be null if previous input didn't have an image
          imageGcpPath: uploadRecipeVideoForm.imageGcpPath,
          imageMd5checksum: uploadRecipeVideoForm.imageMd5checksum,
        };

    // 3. Video fields. if videoFile is provided, proceed to transform it
    // into a videoGcpPath which should be the only field to upload, otherwise
    // pass all existing values
    let videoInput: VideoInput = {};
    const {videoFile, videoGcpPath, videoMd5checksum, muxAssetId} =
      uploadRecipeVideoForm;
    if (videoFile) {
      const newVideoGcpPath = await uploadVideoToGcp(videoFile);
      videoInput = {videoGcpPath: newVideoGcpPath};
    } else {
      // These could be null if previous input didn't have a video (eg draft)
      // Note that form validation is still done upstream to ensure video presence
      // eg for publishing.
      // FC : Make Upstream validation stronger and up to spec with API.
      videoInput = {videoGcpPath, videoMd5checksum, muxAssetId};
    }

    const transformedInput: UploadRecipeInput = {
      ...simpleInput,
      ...imageInput,
      ...videoInput,
    };

    const response = await upsertRecipeVideo({
      variables: {
        uploadRecipeInput: transformedInput,
      },
      refetchQueries: [
        {
          query: GetUploadedRecipesDocument,
          variables: {
            query: '',
            searchConditions: {limit: LIMIT},
          },
        },
      ],
    });
    if (response.errors) {
      throw new Error(
        `Failed to upload the recipe: ${response.errors
          .map(e => e.message)
          .join(', ')}`
      );
    } else if (!response.data) {
      throw new Error('Failed to upload the recipe');
    }

    return response.data.uploadRecipe;
  };

  return processAndUpsertRecipeVideo;
}
