import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {InputMaybe} from '../../services/graphql/apolloTypes';
import {InputActionMeta} from 'react-select';
import {UploadErrors} from 'features/utilities/errors';
import {
  errorStyle,
  inputBoxStyle,
  sectionHeaderInputBoxStyle,
  selectStyle,
} from 'features/utilities/styles';
import {
  ExtendedIngredientEntryInput,
  ExtendedIngredientSectionInput,
} from './upload-recipe-types';

type IngredientSectionProps = {
  ingredientOptions: {value: string; label: string}[];
  sectionIndex: number;
  ingredientSection: ExtendedIngredientSectionInput;
  handleIngredientSectionNameChange: (index: number, value: string) => void;
  handleQuantityChange: (
    sectionIndex: number,
    ingredientIndex: number,
    value: string
  ) => void;
  handleUnitChange: (
    sectionIndex: number,
    ingredientIndex: number,
    value: string
  ) => void;
  handleIngredientPickerChange: (
    sectionIndex: number,
    ingredientIndex: number,
    selectedOption: {
      value: InputMaybe<string> | undefined;
      label: InputMaybe<string> | undefined;
    } | null
  ) => void;
  handleInputChange: (
    value: string,
    action: InputActionMeta,
    sectionIndex: number,
    ingredientIndex: number
  ) => void;
  handlePreparationChange: (
    sectionIndex: number,
    ingredientIndex: number,
    value: string
  ) => void;
  deleteIngredientSection: (sectionIndex: number) => void;
  deleteIngredient: (sectionIndex: number, ingredientIndex: number) => void;
  addIngredientToStartOfSection: (sectionIndex: number) => void;
  uploadErrors: UploadErrors;
};

const IngredientSection: React.FC<IngredientSectionProps> = ({
  ingredientOptions,
  sectionIndex,
  ingredientSection,
  handleIngredientSectionNameChange,
  handleQuantityChange,
  handleUnitChange,
  handleIngredientPickerChange,
  handleInputChange,
  handlePreparationChange,
  deleteIngredientSection,
  deleteIngredient,
  addIngredientToStartOfSection,
  uploadErrors,
}) => {
  const getIngredientPickerValue = (
    ingredient: ExtendedIngredientEntryInput
  ) => {
    if (!ingredient.ingredientId && !ingredient.rawIngredientLine) {
      return null;
    }
    return ingredient.ingredientId
      ? {
          label: ingredient.displayName,
          value: ingredient.ingredientId,
        }
      : {
          label: ingredient.rawIngredientLine,
          value: ingredient.rawIngredientLine,
        };
  };

  return (
    <div key={`ingredient-section-${sectionIndex}`}>
      {sectionIndex === 0 && ( // This line ensures that headers are only shown once at the top of the first ingredient section
        <div className="hidden md:flex justify-between items-center space-x-1 sm:space-x-2 pt-3">
          {/* <img src="/dragicon.svg" alt="drag icon" className="invisible" />{' '} */}
          {/* Invisible drag icon for spacing */}
          <div className="flex w-full justify-between sm:space-x-2 space-x-0 h-8">
            <div className="w-10 sm:w-1/12 sm:text-sm sm:leading-6 font-medium">
              QTY
            </div>
            <div className="w-1/6 font-medium">MEASUREMENT</div>
            <div className="flex-1 font-medium">INGREDIENT</div>
            <div className="flex-1 sm:text-sm font-medium pr-8">
              PREPARATION
            </div>
          </div>
          <div className="text-ash font-bold px-0 sm:pr-2 rounded-md invisible">
            {' '}
            {/* Invisible delete icon for spacing */}
            <img src="/deleteicon.svg" alt="delete icon" />
          </div>
        </div>
      )}
      <div className="flex justify-between items-center space-x-2 pb-3">
        {/* <img src="/dragicon.svg" alt="drag icon" /> */}
        <div className="flex rounded-lg w-full shadow-sm">
          <span className="inline-flex items-center rounded-l-lg border border-r-0 border-taro bg-salt px-3 text-charcoal font-normal sm:text-sm">
            Section
          </span>
          <input
            id={`ingredient-section-name-${sectionIndex}`}
            type="text"
            value={ingredientSection.name}
            onChange={e =>
              handleIngredientSectionNameChange(sectionIndex, e.target.value)
            }
            className={`${sectionHeaderInputBoxStyle} ${
              uploadErrors[`ingredientSection_${sectionIndex}_`] && errorStyle
            }`}
            placeholder="Use sections to organize long ingredient lists. Optional."
          ></input>
        </div>
        <button
          type="button"
          onClick={() => addIngredientToStartOfSection(sectionIndex)}
          className="text-ash font-bold py-1 rounded"
          title="Add ingredient"
        >
          <img src="/addrowicon.svg" alt="add row icon" />
        </button>
        <button
          type="button"
          onClick={() => deleteIngredientSection(sectionIndex)}
          className="text-ash font-bold py-1 sm:pr-2 rounded"
          title="Delete section"
        >
          <img src="/deleteicon.svg" alt="delete icon" />
        </button>
      </div>
      {ingredientSection.ingredients.map((ingredient, ingredientIndex) => (
        <div key={`ingredient-${sectionIndex}-${ingredientIndex}`}>
          <div className="flex justify-between items-center pb-3 space-x-1 sm:space-x-2">
            {/* <img src="/dragicon.svg" alt="drag icon" /> */}
            <div className="flex w-full justify-between sm:space-x-2 space-x-0 h-10">
              <input
                id={`ingredient-quantity-${sectionIndex}-${ingredientIndex}`}
                type="text"
                value={ingredient.quantityStr}
                onChange={e => {
                  handleQuantityChange(
                    sectionIndex,
                    ingredientIndex,
                    e.target.value
                  );
                }}
                placeholder="#"
                className={`${inputBoxStyle} w-10 sm:w-1/12 ${
                  uploadErrors[
                    `quantityStr_${sectionIndex}_${ingredientIndex}`
                  ] && errorStyle
                }`}
              />
              <Creatable
                id={`ingredient-unit-${sectionIndex}-${ingredientIndex}`}
                value={
                  ingredient.unit
                    ? {value: ingredient.unit, label: ingredient.unit}
                    : null
                }
                options={[
                  {value: '', label: '-'},
                  {value: 'tsp', label: 'tsp'},
                  {value: 'tbsp', label: 'tbsp'},
                  {value: 'fl oz', label: 'fl oz'},
                  {value: 'c', label: 'c'},
                  {value: 'pt', label: 'pt'},
                  {value: 'qt', label: 'qt'},
                  {value: 'gal', label: 'gal'},
                  {value: 'oz', label: 'oz'},
                  {value: 'lb', label: 'lb'},
                  {value: '', label: 'Enter custom measurement'},
                ]}
                onChange={selectedOption => {
                  handleUnitChange(
                    sectionIndex,
                    ingredientIndex,
                    selectedOption ? selectedOption.value || '' : ''
                  );
                }}
                placeholder="Select"
                styles={selectStyle}
                className="w-1/6"
              />
              <Select
                id={`ingredient-ingredientPicker-${sectionIndex}-${ingredientIndex}`}
                value={getIngredientPickerValue(ingredient)}
                options={ingredientOptions}
                onChange={selectedOption => {
                  handleIngredientPickerChange(
                    sectionIndex,
                    ingredientIndex,
                    selectedOption
                  );
                }}
                onInputChange={(inputValue, action) => {
                  handleInputChange(
                    inputValue,
                    action,
                    sectionIndex,
                    ingredientIndex
                  );
                }}
                placeholder="Select or enter"
                styles={{
                  ...selectStyle,
                  control: (base, state) => {
                    const creatableControl =
                      typeof selectStyle.control === 'function'
                        ? selectStyle.control(base, state)
                        : base;

                    // Destructure backgroundColor out, get the rest styles
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const {backgroundColor: _, ...otherControlStyles} =
                      creatableControl;

                    return {
                      ...base,
                      ...otherControlStyles,
                      backgroundColor: uploadErrors[
                        `ingredient_${sectionIndex}_${ingredientIndex}`
                      ]
                        ? '#ffeeea'
                        : base.backgroundColor,
                    };
                  },
                }}
                className="flex-1"
              />
              <input
                id={`ingredient-preparation-${sectionIndex}-${ingredientIndex}`}
                type="text"
                value={ingredient.preparation}
                onChange={e => {
                  handlePreparationChange(
                    sectionIndex,
                    ingredientIndex,
                    e.target.value
                  );
                }}
                placeholder="Preparation note"
                className={`${inputBoxStyle} flex-1`}
              />
            </div>
            <button
              type="button"
              onClick={() => deleteIngredient(sectionIndex, ingredientIndex)}
              className="text-ash font-bold py-1 px-0 sm:px-2 rounded-md"
              title="Delete ingredient"
            >
              <img src="/deleteicon.svg" alt="delete icon" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IngredientSection;
