import React, {useEffect, useState} from 'react';
import MuxPlayer from '@mux/mux-player-react/lazy';
import axios from 'axios';
import {FilmIcon} from '@heroicons/react/24/outline';

const POLLING_INTERVAL_MS = 10 * 1000; // 10s

/**
 * Checks if a playback is resolved and ready to be displayed.
 * Note that this method return false if and only if Mux explicitly
 * tells us that the video is processing (error 412). In all
 * other instances, returns true (including other errors and non-existing
 * resources)
 */
async function checkPlaybackState(playbackId: string) {
  try {
    await axios.get(`https://stream.mux.com/${playbackId}.m3u8`);
    return VideoPlaybackState.Resolved;
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.status === 412
    ) {
      return VideoPlaybackState.Pending;
    } else {
      // A different kind of error, we'll let MuxPlayer handle it
      return VideoPlaybackState.Resolved;
    }
  }
}

enum VideoPlaybackState {
  Unknown,
  Pending,
  Resolved,
}

type ReloadableMuxPlayerProps = {
  playbackId: string;
};
/**
 * A thin wrapper around a MuxPlayer.
 *
 * The current Mux behavior is to return an error 412 when requesting
 * a stream URL (constructed from the playbackId) to an asset that is
 * still being processed (eg a newly uploaded video).
 * This error is in turn shown in the original MuxPlayer in a way that
 * is confusing to the creator.
 *
 * This wrapper adds the following functionalities:
 * 1. When a 412 is returned, it display our own UI instead of the
 *    confusing error screen.
 * 2. It regularly polls the stream URL until it resolves (eg return a
 *    working url, or returns a definitive error, eg 404)
 *
 * The state machine is as follows:
 * - States
 *   - Unknown: When the component is loaded or reloaded
 *   - Pending: When we known that the resource is pending (412) and we
 *              need to poll
 *   - Resolved: When we're done polling, either because we fetched our
 *               resource or got an error. In BOTH cases the inner
 *               MuxPlayer handles it.
 * - Transitions
 *   - Whenever the playbackId changes, we restart from scratch (aka Unknown)
 *   - If we're not Resolved, we start polling until we are. Each polling
 *     interval will return Pending or Resolved (so Unknown is realized only
 *     after the playbackId changes, and before the first poll returns)
 *   - When the polling status result is Resolved, we stop polling
 *
 * Ideally this should be superseded by a server side webhook and a
 * push notification flow, but this approach is good enough for now.
 * // TODO: We should move all of this to a webhook server side
 */
export function ReloadableMuxPlayer({
  playbackId,
}: ReloadableMuxPlayerProps): JSX.Element {
  const [playbackState, setPlaybackState] = useState<VideoPlaybackState>(
    VideoPlaybackState.Unknown
  );

  const updatePlaybackState = async () => {
    setPlaybackState(await checkPlaybackState(playbackId));
  };

  useEffect(() => {
    // Restart resolution logic with playbackId changes
    setPlaybackState(VideoPlaybackState.Unknown);
  }, [playbackId]);

  useEffect(() => {
    if (playbackState !== VideoPlaybackState.Resolved) {
      updatePlaybackState(); // First check is run immediately
      const interval = setInterval(updatePlaybackState, POLLING_INTERVAL_MS);
      return () => clearInterval(interval);
    } else {
      // No-op when playback is resolved
      return;
    }
  }, [playbackState]);

  // TODO: Use wrapper instead to correctly round player
  const playerStyle = 'aspect-[9/16] w-64 xl:w-full h-full rounded-lg';

  switch (playbackState) {
    case VideoPlaybackState.Resolved:
      return (
        <MuxPlayer
          className={`${playerStyle} object-cover`}
          playbackId={playbackId || ''}
        />
      );
    case VideoPlaybackState.Pending:
      return (
        <div
          className={`${playerStyle} flex flex-col justify-center items-center gap-2 xl:gap-5 py-4 bg-gradient-to-tr from-emerald-100 to-fuchsia-100 select-none`}
        >
          <FilmIcon className="h-12 w-12 fill-charcoal" />
          <div className="text-center px-2 xl:px-8">
            <p className="text-charcoal text-sm xl:text-base">
              Your video is being processed
            </p>
            <p className="text-truffle text-xs pt-2">
              Check back in a few minutes to see the video. Feel free to leave
              this page.
            </p>
          </div>
        </div>
      );
    case VideoPlaybackState.Unknown:
      return <div className={`${playerStyle} bg-black`} />;
  }
}
