import React from 'react';
import {UploadedRecipeStatus} from '../../services/graphql/apolloTypes';
import {useNavigate} from 'react-router-dom';
import {UPLOAD_ROUTE} from 'app/routes';
import InfiniteScroll from 'react-infinite-scroller';

type RecipeData = {
  id: string;
  imageUrl?: string | null;
  title: string;
  publishTime?: string | null;
  status: string;
  notesToCreator?: string | null;
};

type UploadedRecipesProps = {
  recipes: RecipeData[];
  hasMore: boolean;
  handleMore: () => Promise<void>;
};

const displayMap = new Map<string, {displayName: string; style: string}>([
  [
    UploadedRecipeStatus.Accepted,
    {
      displayName: 'Published',
      style: 'bg-green-100 text-green-800',
    },
  ],
  [
    UploadedRecipeStatus.Rejected,
    {
      displayName: 'Rejected',
      style: 'bg-red-100 text-red-800',
    },
  ],
  [
    UploadedRecipeStatus.Unpublished,
    {
      displayName: 'Unpublished',
      style: 'bg-orange-100 text-orange-800',
    },
  ],
  [
    UploadedRecipeStatus.InReview,
    {
      displayName: 'In Review',
      style: 'bg-cream text-caramel-700',
    },
  ],
  [
    UploadedRecipeStatus.Draft,
    {
      displayName: 'Draft',
      style: 'bg-gray-100 text-gray-800',
    },
  ],
]);

function UploadedRecipes({
  recipes,
  hasMore,
  handleMore,
}: UploadedRecipesProps): JSX.Element {
  const navigate = useNavigate();

  const leftPageMarginWidth = 'ml-1/20 lg:ml-1/10';
  const rightPageMarginWidth = 'mr-1/20 lg:mr-1/10';

  const thClass = 'font-semibold text-truffle p-4';
  const tdClass = 'p-4 text-truffle';

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const options = {year: 'numeric', month: 'short', day: 'numeric'} as const;
    return date.toLocaleDateString(undefined, options);
  };

  // TODO: Add overflow rules and Tooltip for long reviewer's notes
  const createRow = (recipe: RecipeData, clickable: boolean) => {
    const displayInfo = displayMap.get(recipe.status);
    return (
      <tr
        key={recipe.id}
        className={
          clickable
            ? 'cursor-pointer hover:bg-caramel-100 border h-4'
            : 'border h-4'
        }
        onClick={() => clickable && navigate(`${UPLOAD_ROUTE}/${recipe.id}`)}
      >
        <td className={tdClass}>
          {recipe.imageUrl && (
            <img src={recipe.imageUrl} alt={recipe.title} className="w-10" />
          )}
        </td>
        <td className={tdClass}>{recipe.title}</td>
        <td className={tdClass}>
          {recipe.publishTime ? formatDate(recipe.publishTime) : 'N/A'}
        </td>
        <td className={tdClass}>
          {displayInfo && (
            <span
              className={`px-2 p-1 rounded text-base font-medium ${displayInfo.style}`}
            >
              {displayInfo.displayName}
            </span>
          )}
        </td>
        <td className={tdClass}>{recipe.notesToCreator ?? 'N/A'}</td>
      </tr>
    );
  };

  return (
    <div
      className={`bg-white overflow-x-auto ${leftPageMarginWidth} ${rightPageMarginWidth} rounded-xl shadow`}
    >
      <table className={'w-full'}>
        <thead>
          <tr className="text-left border text-xs">
            <th className={thClass}>PREVIEW</th>
            <th className={thClass}>TITLE</th>
            <th className={thClass}>PUBLISHED DATE</th>
            <th className={thClass}>STATUS</th>
            <th className={thClass}>REVIEWER&apos;S NOTES</th>
          </tr>
        </thead>
        <InfiniteScroll
          element="tbody"
          pageStart={0}
          loadMore={handleMore}
          hasMore={hasMore}
          loader={
            <tr key={0}>
              <td colSpan={5} align="center">
                Loading...
              </td>
            </tr>
          }
        >
          {recipes.map((recipe: RecipeData) =>
            createRow(recipe, recipe.status !== UploadedRecipeStatus.InReview)
          )}
        </InfiniteScroll>
      </table>
    </div>
  );
}

export default UploadedRecipes;
