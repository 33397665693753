import React, {useContext, useEffect, useState, useRef} from 'react';
import {
  UploadedRecipeStatus,
  GetUploadedRecipeDocument,
  RecipeTagEntryInput,
  InstructionSectionInput,
} from '../../services/graphql/apolloTypes';
import {
  IngredientsProvider,
  TagsProvider,
  TagsContext,
} from '../../components/autocomplete/AutocompleteProviders';
import {
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import InstructionSections from './InstructionSections';
import IngredientSections from './IngredientSections';
import TagsSection from './TagsSection';
import {Form, Formik, Field} from 'formik';
import {useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {UPLOAD_ROUTE, VIDEOS_ROUTE} from 'app/routes';
import {
  buttonStyle,
  errorMessageStyle,
  errorStyle,
  inputBoxStyle,
  headerStyle,
} from 'features/utilities/styles';
import {UploadErrors} from '../utilities/errors';
import {useUnloadGuard} from 'commonreact';
import {useRecipeVideoUpload} from 'features/utilities/useRecipeVideoUpload';
import {
  DisplayStatus,
  ExtendedIngredientSectionInput,
  UploadRecipeVideoForm,
} from './upload-recipe-types';
import {
  getIngredientText,
  getInstructionText,
  parseToTotalTimeInput,
  parseToUplaodRecipeVideoForm,
  refreshTags,
  validateForm,
} from './converters';
import _ from 'lodash';
import ImageDropzone from 'features/upload/ImageDropzone';
import VideoDropzone from 'features/upload/VideoDropzone';

/* FC TODOs

- implement first fetch loading/clearing stats.
- Change Dropzone contract to really just return a File
- Check filename on upload issues
- Fix Navbar position & Generic Loading screens

*/

const toDisplayStatus = (
  uploadedRecipeStatus: UploadedRecipeStatus | undefined
) => {
  switch (uploadedRecipeStatus) {
    case undefined:
      return DisplayStatus.NEW;
    case UploadedRecipeStatus.Draft:
      return DisplayStatus.DRAFT;
    case UploadedRecipeStatus.InReview:
      return DisplayStatus.IN_REVIEW;
    case UploadedRecipeStatus.Rejected:
    case UploadedRecipeStatus.Unpublished:
      return DisplayStatus.UNPUBLISHED;
    case UploadedRecipeStatus.Accepted:
      return DisplayStatus.LIVE;
  }
};

const options: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

const displayMap = new Map<DisplayStatus, {displayName: string; style: string}>(
  [
    [
      DisplayStatus.NEW,
      {
        displayName: 'Draft',
        style: 'bg-blue-50 text-blue-700 ring-blue-700/10',
      },
    ],
    [
      DisplayStatus.DRAFT,
      {
        displayName: 'Draft',
        style: 'bg-blue-50 text-blue-700 ring-blue-700/10',
      },
    ],
    [
      DisplayStatus.IN_REVIEW,
      {
        displayName: 'In Review',
        style: 'bg-red-50 text-red-700 ring-red-600/10 ${commonStyle}',
      },
    ],
    [
      DisplayStatus.LIVE,
      {
        displayName: 'Published',
        style: 'bg-green-50 text-green-700 ring-green-600/20',
      },
    ],
    [
      DisplayStatus.UNPUBLISHED,
      {
        displayName: 'Unpublished',
        style: 'bg-orange-50 text-orange-700 ring-orange-600/20',
      },
    ],
  ]
);

function UploadRecipe(): JSX.Element {
  // Navigation and parameters
  const {id} = useParams(); // if an id is present, we are editing a recipe
  const navigate = useNavigate();
  const {items: tags} = useContext(TagsContext);

  // UI States
  const ingredientsRef = useRef<HTMLButtonElement>(null);
  const instructionsRef = useRef<HTMLButtonElement>(null);
  const [currentPreview, setCurrentPreview] = useState<string>('video');

  const [mainErrorMessage, setMainErrorMessage] = useState<string | null>(null);
  const [submittingMessage, setSubmittingMessage] =
    useState<string>('Saving changes...');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [instructionFreeText, setInstructionFreeText] = useState('');
  const [ingredientFreeText, setIngredientFreeText] = useState('');

  // FC : Move/Merge these states into proper submission state, diff, touched, and error
  // handling and validation
  // FC Note: We also need to handle formik's implicit `isSubmitting`
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputChanged, setInputChanged] = useState(false); // Replace with input diffs
  const [uploadErrors, setUploadErrors] = useState<UploadErrors>({}); // Manual Field validation. Replace with zod.

  // Queries and mutations
  const {
    data: uploadRecipeRequest,
    loading: uploadRecipeLoading,
    error: uploadRecipeError,
    refetch: uploadRecipeRefetch,
  } = useQuery(GetUploadedRecipeDocument, {
    variables: {id: id || ''},
    skip: !id,
    fetchPolicy: 'no-cache', // don't want to cache this query as when we edit recipes we want to see the latest version
  });
  const previousUploadedRecipe = uploadRecipeRequest?.getUploadedRecipe;

  const [initialValues, setInitialValues] = useState<UploadRecipeVideoForm>(
    parseToUplaodRecipeVideoForm(previousUploadedRecipe)
  );

  const [targetStatus, setTargetStatus] = useState<UploadedRecipeStatus>(
    UploadedRecipeStatus.Draft
  );

  // Correctly reset the form initial values if tags or the upload recipe gets correctly set.
  useEffect(() => {
    const newFormValues = parseToUplaodRecipeVideoForm(previousUploadedRecipe);
    const updatedTags = refreshTags(newFormValues.recipeTagEntries, tags);

    setInitialValues({...newFormValues, recipeTagEntries: updatedTags});
  }, [previousUploadedRecipe, tags]);

  useEffect(() => {
    if (successMessage) {
      // Start a timer to clear successMessage whenever it is set
      const timer = setTimeout(() => setSuccessMessage(null), 5000);
      // Cancel timer whenever a newer message comes in, or the current one clears.
      return () => clearTimeout(timer);
    } else {
      return () => {};
    }
  }, [successMessage]);

  const processAndUpsertRecipeVideo =
    useRecipeVideoUpload(setSubmittingMessage);

  // Render variables

  // FC TODO: handle errors first load errors
  if (id && !previousUploadedRecipe) {
    if (uploadRecipeLoading) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <p>Errors : {JSON.stringify(uploadRecipeError)}</p>
        </div>
      );
    }
  }
  if (previousUploadedRecipe?.status === UploadedRecipeStatus.InReview) {
    setSuccessMessage(null); // In case we come from a successful submission for review.
    // TODO Make this into a toast.
    alert(
      'This recipe is now successfully submitted and cannot be edited during review.'
    );
    // Redirect to recipes dashboard
    navigate(VIDEOS_ROUTE);
  }
  // END edge cases handling

  const currentDisplayStatus = toDisplayStatus(previousUploadedRecipe?.status);
  const displayInfo = displayMap.get(currentDisplayStatus);
  const isDraftAllowed = currentDisplayStatus !== DisplayStatus.LIVE;

  const date = previousUploadedRecipe
    ? new Date(previousUploadedRecipe.updatedAt).toLocaleDateString(
        'en-US',
        options
      )
    : ''; // Won't happen
  const savedStatusString =
    currentDisplayStatus !== DisplayStatus.NEW
      ? [
          `Last ${
            currentDisplayStatus === DisplayStatus.LIVE ? 'published' : 'saved'
          } ${date}`,
        ]
      : [];

  // Event handlers

  const handleSubmit = async (values: UploadRecipeVideoForm) => {
    setSubmittingMessage('Saving changes...');
    setMainErrorMessage(null);
    setSuccessMessage(null);
    try {
      // 1. Performing Validation
      const validationErrors = validateForm(values, targetStatus, tags);
      if (validationErrors) {
        // If there are any errors, set them in each form
        setUploadErrors(validationErrors);
        // And show a global warning
        setMainErrorMessage(
          'Please fill in the required fields highlighted in red'
        );
        return;
      } else {
        // If there are no errors, clear previous ones.
        setUploadErrors({});
        const newUploadRecipe = await processAndUpsertRecipeVideo(
          values,
          targetStatus
        );
        setSuccessMessage(
          targetStatus === UploadedRecipeStatus.InReview
            ? 'Video published successfully!'
            : 'Changes saved successfully!'
        );
        // Directly goes to refresh if the video is published or saved as draft.
        // In case the review process is turned back on, will trigger a redirect
        // to the video index page in the block above
        // annotated "// Redirect to recipes dashboard"
        const newId = newUploadRecipe.id;
        if (id) {
          uploadRecipeRefetch();
        } else {
          // Navigate to saved video entry. The success message should still continue to show
          navigate(`${UPLOAD_ROUTE}/${newId}`);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setMainErrorMessage(error.message);
      } else {
        setMainErrorMessage('An unexpected error occurred');
      }
    }
  };

  // preview focus button handlers
  const handleNavigationButtonClick = (value: 'ingredient' | 'instruction') => {
    if (ingredientsRef.current && value === 'ingredient') {
      ingredientsRef.current.focus();
    }
    if (instructionsRef.current && value === 'instruction') {
      instructionsRef.current.focus();
    }
  };

  // Styles
  const boxStyle =
    'flex flex-col xl:ml-auto xl:mr-20 mx-10 xl:w-3/5 p-5 mt-6 rounded-lg shadow bg-white font-medium text-xs md:text-sm text-truffle';

  return (
    <Formik
      key={id}
      initialValues={initialValues}
      onSubmit={async (values, {setSubmitting}) => {
        await handleSubmit(values);
        setSubmitting(false);
      }}
      enableReinitialize // this allows us to reset the form values when formValues correct sets
    >
      {({isSubmitting, setFieldValue, values}) => {
        const isChanged = !_.isEqual(initialValues, values);
        // Prevent Page closure when there are unsaved changes, or when submission is happening
        useUnloadGuard(isChanged || isSubmitting);
        const totalTime = parseToTotalTimeInput(
          values.prepTimeMins,
          values.cookTimeMins
        );
        const dirtyString = isChanged ? ['You have unsaved changes'] : [];
        const showCheckmark =
          currentDisplayStatus !== DisplayStatus.NEW && !isChanged;
        const comboStatusString = dirtyString
          .concat(savedStatusString)
          .join(', ');

        const setVideoFile = (videoFile: File | null) => {
          setInputChanged(true);
          setFieldValue('videoFile', videoFile);
        };
        const setImageFile = (imageFile: File | null) => {
          setInputChanged(true);
          setFieldValue('imageFile', imageFile);
        };
        const setIngredientSections = (
          ingredientSections: ExtendedIngredientSectionInput[]
        ) => {
          setFieldValue('ingredientSections', ingredientSections);
        };
        const setInstructionSections = (
          instructionSections: InstructionSectionInput[]
        ) => {
          setFieldValue('instructionSections', instructionSections);
        };
        const setRecipeTagEntries = (
          recipeTagEntries: RecipeTagEntryInput[]
        ) => {
          setFieldValue('recipeTagEntries', recipeTagEntries);
        };
        return (
          <div className="w-full min-h-screen bg-salt pt-1 pb-48">
            {isSubmitting && (
              <div className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-80 z-20" />
            )}
            <Form>
              <div className="xl:flex">
                <div className="fixed hidden xl:flex xl:ml-1/20 min-h-[44rem] w-88 p-6 mt-6 rounded-lg shadow bg-white font-medium text-sm text-truffle flex-col justify-start gap-4">
                  <div className="text-charcoal flex">
                    <button
                      type="button"
                      onClick={() => setCurrentPreview('video')}
                      className={`${
                        currentPreview === 'video' ? 'bg-salt' : 'bg-white'
                      } w-1/3 relative items-center rounded-l-lg px-3 py-2 text-sm flex-grow shadow-sm border border-taro`}
                    >
                      Video
                    </button>
                    <button
                      type="button"
                      onClick={() => setCurrentPreview('ingredients')}
                      className={`${
                        currentPreview === 'ingredients'
                          ? 'bg-salt'
                          : 'bg-white'
                      }
                        w-1/3 relative items-center px-3 py-2 text-sm flex-grow border-t border-b border-taro shadow-sm`}
                    >
                      Ingredients
                    </button>
                    <button
                      type="button"
                      onClick={() => setCurrentPreview('instructions')}
                      className={`${
                        currentPreview === 'instructions'
                          ? 'bg-salt'
                          : 'bg-white'
                      } border border-taro w-1/3 relative -ml-px items-center rounded-r-lg px-3 py-2 text-sm flex-grow shadow-sm`}
                    >
                      Directions
                    </button>
                  </div>
                  {currentPreview === 'video' && (
                    <VideoDropzone
                      existingVideoPlaybackId={
                        uploadRecipeRequest?.getUploadedRecipe?.videoPlaybackId
                      }
                      pendingFile={values.videoFile}
                      onFileDrop={setVideoFile}
                      uploadErrorMessage={uploadErrors.videoFile}
                      clearUploadErrorMessage={() => {
                        if (uploadErrors && uploadErrors.videoFile) {
                          setUploadErrors({
                            ...uploadErrors,
                            videoFile: '',
                          });
                        }
                      }}
                    />
                  )}
                  {currentPreview === 'ingredients' && (
                    <div className="grow rounded-lg p-3 border text-charcoal overflow-y-auto overflow-wrap break-words flex flex-col">
                      {values.ingredientSections.some(section => {
                        return (
                          section.name !== '' ||
                          section.ingredients.some(
                            ingredient =>
                              ingredient.ingredientId !== '' ||
                              ingredient.preparation !== '' ||
                              ingredient.rawIngredientLine !== '' ||
                              ingredient.unit !== '' ||
                              ingredient.quantityStr !== ''
                          )
                        );
                      }) ? (
                        values.ingredientSections.map((section, index) => (
                          <div key={index}>
                            <p className="text-lg font-semibold pt-1">
                              {section.name}
                            </p>
                            <ul className="pt-2 font-normal">
                              {section.ingredients.map((ingredient, index) => (
                                <li className="flex py-1" key={index}>
                                  <div>
                                    {ingredient.quantityStr} {ingredient.unit}
                                  </div>
                                  <div className="ml-auto w-2/3 font-semibold">
                                    {ingredient.ingredientId
                                      ? ingredient.displayName
                                          .charAt(0)
                                          .toUpperCase() +
                                        ingredient.displayName.slice(1)
                                      : ingredient.rawIngredientLine &&
                                        ingredient.rawIngredientLine
                                          .charAt(0)
                                          .toUpperCase() +
                                          ingredient.rawIngredientLine.slice(1)}
                                    {ingredient.preparation && (
                                      <span className="font-normal text-truffle">
                                        , {ingredient.preparation}
                                      </span>
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <div className="grow flex flex-col items-center justify-center text-truffle font-semibold">
                          <p>You haven&apos;t filled this out yet.</p>
                          <button
                            type="button"
                            className="h-10 mt-4 px-3 bg-yolk text-white border shadow rounded-md text-center font-normal text-sm"
                            onClick={() =>
                              handleNavigationButtonClick('ingredient')
                            }
                          >
                            Let&apos;s do it
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {currentPreview === 'instructions' && (
                    <div className="grow rounded-lg p-3 border text-charcoal overflow-y-auto overflow-wrap break-words flex flex-col">
                      {values.instructionSections.some(section => {
                        return (
                          section.name !== '' ||
                          section.instructions.some(
                            instruction => instruction !== ''
                          )
                        );
                      }) ? (
                        values.instructionSections.map((section, index) => (
                          <div key={index}>
                            <p className="text-lg font-semibold pt-1">
                              {section.name}
                            </p>
                            <ol className="pt-2 font-normal">
                              {section.instructions.map(
                                (instruction, index) => (
                                  <li className="flex py-1" key={index}>
                                    <span className="w-4">{index + 1}.</span>
                                    <div className="pl-2 flex-grow">
                                      {instruction}
                                    </div>
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                        ))
                      ) : (
                        <div className="grow flex flex-col items-center justify-center text-truffle font-semibold">
                          <p>You haven&apos;t filled this out yet.</p>
                          <button
                            type="button"
                            className="h-10 mt-4 px-3 bg-yolk text-white border shadow rounded-md text-center font-normal text-sm"
                            onClick={() =>
                              handleNavigationButtonClick('instruction')
                            }
                          >
                            Let&apos;s do it
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={boxStyle}>
                  <h2 className={headerStyle}>Overview</h2>
                  <div className="flex flex-col space-y-2 pt-2">
                    <label htmlFor="title">Title</label>

                    <Field
                      id="title"
                      type="text"
                      name="title"
                      placeholder="Enter title of your video"
                      maxLength={60}
                      className={`${inputBoxStyle} ${
                        uploadErrors.title && errorStyle
                      }`}
                      onInput={() => {
                        setInputChanged(true);
                        if (uploadErrors.title) {
                          setUploadErrors({...uploadErrors, title: ''});
                        }
                      }}
                    />
                    {values.title.length === 60 && (
                      <p className="text-shell font-normal">
                        You have reached the maximum character limit, 60
                        characters.
                      </p>
                    )}
                    {uploadErrors.title && (
                      <p className={errorMessageStyle}>{uploadErrors.title}</p>
                    )}

                    <div className="xl:hidden">
                      <label htmlFor="video">Video</label>
                      <VideoDropzone
                        existingVideoPlaybackId={
                          uploadRecipeRequest?.getUploadedRecipe
                            ?.videoPlaybackId
                        }
                        pendingFile={values.videoFile}
                        onFileDrop={setVideoFile}
                        uploadErrorMessage={uploadErrors.videoFile}
                        clearUploadErrorMessage={() => {
                          if (uploadErrors && uploadErrors.videoFile) {
                            setUploadErrors({
                              ...uploadErrors,
                              videoFile: '',
                            });
                          }
                        }}
                      />
                    </div>

                    <label htmlFor="image">Preview image</label>

                    <ImageDropzone
                      existingUrl={
                        uploadRecipeRequest?.getUploadedRecipe?.imageUrl
                      }
                      pendingFile={values.imageFile}
                      onFileDrop={setImageFile}
                      uploadErrorMessage={uploadErrors.imageFile}
                      clearUploadErrorMessage={() => {
                        if (uploadErrors && uploadErrors.imageFile) {
                          setUploadErrors({
                            ...uploadErrors,
                            imageFile: '',
                          });
                        }
                      }}
                    />

                    <label htmlFor="description">Description</label>
                    <p className="font-light pb-2">
                      We recommend including keywords that will help users find
                      your video. This information is an important part of our
                      search algorithm.
                    </p>
                    <Field
                      id="description"
                      as="textarea"
                      rows="5"
                      name="description"
                      placeholder="Write a description"
                      className={inputBoxStyle}
                    />

                    <div className="flex flex-col md:flex-row pt-2 space-y-2 md:space-y-0">
                      <div className="flex flex-col space-y-2 md:pr-12">
                        <label className="w-full" htmlFor="yieldServings">
                          Yield serving size
                        </label>
                        {uploadErrors.yieldServings && (
                          <p className={errorMessageStyle}>
                            {uploadErrors.yieldServings}
                          </p>
                        )}
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => {
                              setInputChanged(true);
                              setFieldValue(
                                'yieldServings',
                                Math.max(Number(values.yieldServings) - 1, 0)
                              );
                              if (uploadErrors.yieldServings) {
                                setUploadErrors({
                                  ...uploadErrors,
                                  yieldServings: '',
                                });
                              }
                            }}
                            className="border border-r-0 rounded-l-lg w-10 h-10 text-charcoal text-center flex justify-center items-center shadow-sm border-taro"
                          >
                            <img
                              src="/minusicon.svg"
                              alt="minus icon"
                              className="w-4 h-4"
                            />
                          </button>
                          <Field
                            type="number"
                            id="yieldServings"
                            name="yieldServings"
                            className={`${inputBoxStyle} rounded-none border-t w-10 h-10 border-b text-center shadow-sm p-2 ${
                              uploadErrors.yieldServings && errorStyle
                            }`}
                            onInput={() => {
                              setInputChanged(true);
                              if (uploadErrors.yieldServings) {
                                setUploadErrors({
                                  ...uploadErrors,
                                  yieldServings: '',
                                });
                              }
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setInputChanged(true);
                              setFieldValue(
                                'yieldServings',
                                Number(values.yieldServings) + 1
                              );
                              if (uploadErrors.yieldServings) {
                                setUploadErrors({
                                  ...uploadErrors,
                                  yieldServings: '',
                                });
                              }
                            }}
                            className="border border-l-0 border-taro w-10 h-10 rounded-r-lg text-charcoal flex justify-center items-center shadow-sm"
                          >
                            <img
                              src="/plusicon.svg"
                              alt="plus icon"
                              className="w-4 h-4"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col space-y-2 md:pr-2 relative">
                        <label htmlFor="prepTimeMins">Prep time</label>
                        {uploadErrors.prepTimeMins && (
                          <p className={errorMessageStyle}>
                            {uploadErrors.prepTimeMins}
                          </p>
                        )}
                        <div className="relative">
                          <Field
                            type="number"
                            id="prepTimeMins"
                            name="prepTimeMins"
                            className={`${inputBoxStyle} ${
                              uploadErrors.prepTimeMins && errorStyle
                            } w-40 pr-10`}
                            onInput={() => {
                              if (uploadErrors.prepTimeMins) {
                                setUploadErrors({
                                  ...uploadErrors,
                                  prepTimeMins: '',
                                });
                              }
                              if (uploadErrors.totalTimeMins) {
                                setUploadErrors({
                                  ...uploadErrors,
                                  totalTimeMins: '',
                                });
                              }
                            }}
                          />
                          <span className="absolute inset-y-3 right-2 text-sm text-shell font-normal pointer-events-none">
                            Minutes
                          </span>
                        </div>
                      </div>

                      <div className="flex flex-col space-y-2 md:pr-4 relative">
                        <label htmlFor="cookTimeMins">Cook time</label>
                        {uploadErrors.cookTimeMins && (
                          <p className={errorMessageStyle}>
                            {uploadErrors.cookTimeMins}
                          </p>
                        )}
                        <div className="relative">
                          <Field
                            type="number"
                            id="cookTimeMins"
                            name="cookTimeMins"
                            className={`${inputBoxStyle} ${
                              uploadErrors.cookTimeMins && errorStyle
                            } w-40 pr-10`}
                            onInput={() => {
                              if (uploadErrors.cookTimeMins) {
                                setUploadErrors({
                                  ...uploadErrors,
                                  cookTimeMins: '',
                                });
                              }
                              if (uploadErrors.totalTimeMins) {
                                setUploadErrors({
                                  ...uploadErrors,
                                  totalTimeMins: '',
                                });
                              }
                            }}
                          />
                          <span className="absolute inset-y-3 right-2 text-sm text-shell font-normal pointer-events-none">
                            Minutes
                          </span>
                        </div>
                      </div>

                      <div className="flex flex-col pt-2 md:pt-10 text-shell font-normal">
                        <span>
                          Total time: {totalTime ? `${totalTime} mins` : '--'}
                        </span>
                        {uploadErrors.totalTimeMins && (
                          <p className={errorMessageStyle}>
                            {uploadErrors.totalTimeMins}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={boxStyle}>
                <IngredientsProvider>
                  <IngredientSections
                    ingredientSections={values.ingredientSections}
                    setIngredientSections={setIngredientSections}
                    ingredientFreeText={ingredientFreeText}
                    setIngredientFreeText={setIngredientFreeText}
                    ingredientsRef={ingredientsRef}
                    uploadErrors={uploadErrors}
                    setUploadErrors={setUploadErrors}
                  />
                </IngredientsProvider>
              </div>

              <div className={boxStyle}>
                <InstructionSections
                  instructionSections={values.instructionSections}
                  setInstructionSections={setInstructionSections}
                  instructionFreeText={instructionFreeText}
                  setInstructionFreeText={setInstructionFreeText}
                  instructionsRef={instructionsRef}
                  uploadErrors={uploadErrors}
                  setUploadErrors={setUploadErrors}
                />
              </div>

              <div className={boxStyle}>
                <TagsProvider>
                  <TagsSection
                    title={values.title}
                    recipeTagEntries={values.recipeTagEntries}
                    setRecipeTagEntries={setRecipeTagEntries}
                    uploadErrors={uploadErrors}
                    ingredientFreeText={getIngredientText(
                      values.ingredientSections,
                      ingredientFreeText
                    )}
                    instructionFreeText={getInstructionText(
                      values.instructionSections,
                      instructionFreeText
                    )}
                  />
                </TagsProvider>
              </div>

              {mainErrorMessage && (
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-24 z-50 ">
                  <div className="flex space-x-6 items-center justify-center text-sm font-medium text-center py-3 px-6 rounded-lg bg-tomatotint text-tomatoshade shadow-sm">
                    <ExclamationCircleIcon className="h-8 w-8 fill-tomatoshade" />
                    <div className="max-w-60 md:max-w-sm cursor-default">
                      <p className="break-words">{mainErrorMessage}</p>
                    </div>
                    <XMarkIcon
                      className="h-6 w-6 fill-tomatoshade cursor-pointer"
                      onClick={() => {
                        setMainErrorMessage(null);
                      }}
                    />
                  </div>
                </div>
              )}
              {isSubmitting && (
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-24 z-50 ">
                  <div className="flex space-x-6 items-center justify-center text-sm font-medium text-center py-3 px-6 rounded-lg bg-cream text-charcoal shadow-sm">
                    <ExclamationTriangleIcon className="h-8 w-8 fill-gray-700" />

                    <div className="max-w-60 md:max-w-96 cursor-default">
                      <p className="break-words">
                        {submittingMessage} Please do not close this window
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {successMessage && (
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-24 z-50 ">
                  <div className="flex space-x-6 items-center justify-center text-sm font-medium text-center py-3 px-6 rounded-lg bg-green-50 text-green-700 shadow-sm">
                    <CheckCircleIcon className="h-8 w-8 fill-green-800" />

                    <div className="max-w-60 md:max-w-96 cursor-default">
                      <p className="break-words">{successMessage}</p>
                    </div>
                    <XMarkIcon
                      className="h-6 w-6 fill-green-800 cursor-pointer"
                      onClick={() => {
                        setSuccessMessage(null);
                      }}
                    />
                  </div>
                </div>
              )}

              <div
                className={
                  'flex flex-col sm:ml-auto mx-10 mt-4 rounded-lg shadow-sm bg-white font-medium text-xs sm:text-sm text-truffle sm:w-full sm:fixed sm:bottom-0 sm:py-0 sm:pr-2 sm:border-t sm:rounded-none z-40'
                }
              >
                <div className="flex justify-between space-x-4 px-4 md:px-0 py-3">
                  <div className="flex items-baseline gap-2 py-2 md:px-4">
                    <div className="font-semibold text-base">
                      {values.title}
                    </div>
                    <div className="font-semibold">
                      {displayInfo && (
                        <span
                          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${displayInfo.style}`}
                        >
                          {displayInfo.displayName}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex gap-2">
                    {!isSubmitting && (
                      <div className="flex space-x-2 items-center mr-2">
                        {showCheckmark && (
                          <img src="/checkcircle.svg" alt="delete icon" />
                        )}
                        <div className="text-center text-shell font-normal text-sm md:text-base whitespace-nowrap py-2">
                          {comboStatusString}
                        </div>
                      </div>
                    )}
                    {isDraftAllowed && (
                      <button
                        type="submit"
                        onClick={() => {
                          setTargetStatus(UploadedRecipeStatus.Draft);
                        }}
                        disabled={isSubmitting}
                        className={`px-3 h-10 ${
                          !isSubmitting
                            ? 'bg-charcoal text-white'
                            : 'bg-salt text-light'
                        } ${buttonStyle}`}
                      >
                        Save Draft
                      </button>
                    )}
                    <button
                      type="submit"
                      onClick={() => {
                        setTargetStatus(UploadedRecipeStatus.InReview);
                      }}
                      disabled={isSubmitting}
                      className={`px-3 h-10 ${
                        !isSubmitting
                          ? 'bg-yolk text-white'
                          : 'bg-salt text-light'
                      } ${buttonStyle}`}
                    >
                      {isDraftAllowed ? 'Publish' : 'Update'}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default UploadRecipe;
