import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {AuthProvider} from '../services/auth/AuthProvider';
import Router from './Router';
import flavrsClient from '../services/graphql/apolloClient';
import '../index.css';

function App(): JSX.Element {
  return (
    <React.StrictMode>
      <ApolloProvider client={flavrsClient}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
}

export default App;
