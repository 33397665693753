import React from 'react';

export enum AlertBannerType {
  // TODO: add the support for SUCCESS
  ERROR,
  NEUTRAL,
}

interface AlertBannerProps {
  type: AlertBannerType;
  icon: JSX.Element;
  message: string;
  actionText?: string;
  handleActionClick?: () => Promise<void>;
}

function AlertBanner({
  type,
  icon,
  message,
  actionText,
  handleActionClick,
}: AlertBannerProps) {
  let boxStyle = '';
  switch (type) {
    case AlertBannerType.NEUTRAL:
      boxStyle = 'bg-cream text-truffle';
      break;
    case AlertBannerType.ERROR:
      boxStyle = 'bg-tomatotint text-tomatoshade';
      break;
    default:
  }

  const getActionComponent = () => {
    if (!actionText) return null;
    if (handleActionClick) {
      return (
        <div onClick={handleActionClick} className="font-bold cursor-pointer">
          {actionText}
        </div>
      );
    } else {
      return <div className="font-bold">{actionText}</div>;
    }
  };

  return (
    <div
      className={`flex justify-between p-4 shadow-sm rounded-md text-sm font-inter space-x-1 ${boxStyle}`}
    >
      <div className="flex justify-start gap-2">
        {icon}
        <p className="font-medium">{message}</p>
      </div>

      {getActionComponent()}
    </div>
  );
}
export default AlertBanner;
