import React, {useState} from 'react';
import {
  MeDocument,
  UpdateCreatorDocument,
} from '../../services/graphql/apolloTypes';
import {useMutation, useQuery} from '@apollo/client';
import * as Yup from 'yup';
import {buttonStyle, headerStyle} from '../utilities/styles';
import Logout from 'features/auth/Logout';
import ErrorPanel from '../utilities/ErrorPanel';

const validationSchema = Yup.object().shape({
  displayName: Yup.string()
    .min(2, 'Display name must be at least 2 characters')
    .max(30, 'Display name must be at most 30 characters'),
  username: Yup.string()
    .min(3, 'Username must be at least 3 characters')
    .max(30, 'Username must be at most 30 characters'),
});

function Profile(): JSX.Element {
  const {data, loading, error} = useQuery(MeDocument);
  const [updateCreator] = useMutation(UpdateCreatorDocument);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const profile = data?.me;
  if (!profile) return <div>No profile found</div>;

  const [displayName, setDisplayName] = useState(profile.displayName);
  const [username, setUsername] = useState(profile.username);
  const [thumbnailUrl, setThumbnailUrl] = useState(profile.avatarUrl || '');
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const leftPageMarginWidth = 'w-1/20 lg:w-1/10';
  const rightBoxLeftMargin = 'ml-5 md:ml-10';

  const handleSaveEdits = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      await validationSchema.validate({displayName, username});

      await updateCreator({
        variables: {
          creatorId: profile.id,
          creatorInput: {username, displayName, avatarFile},
        },
        refetchQueries: [{query: MeDocument}],
      });
      setErrorMsg('');
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      if (error instanceof Yup.ValidationError) {
        setErrorMsg(error.errors.join('\n'));
      } else if (error instanceof Error) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg('Error updating profile');
      }
    }
  };

  return (
    <div className="w-full h-screen bg-salt">
      <div className="flex flex-row justify-start items-end w-full h-12">
        <div className={leftPageMarginWidth}></div>
        <div className={headerStyle}>Profile</div>
      </div>

      <div className="flex flex-row justify-start w-full h-12">
        <div className={leftPageMarginWidth}></div>
        <div className="w-1/3 lg:w-1/5 h-full"></div>
        <div className={`w-1/2 lg:w-3/5 ${rightBoxLeftMargin} text-right`}>
          <Logout />
        </div>
      </div>

      <form onSubmit={handleSaveEdits}>
        <div className="flex flex-row justify-start items-start w-full h-96">
          <div className={leftPageMarginWidth}></div>

          <div className="flex flex-col justify-start items-start w-1/3 lg:w-1/5 h-full p-4 md:p-5 bg-white rounded-lg border border-taro shadow">
            <div className="h-1/6 font-normal text-xs md:text-sm text-truffle">
              Profile Photo
            </div>

            {thumbnailUrl && (
              <div className="flex justify-center w-full">
                <img
                  src={thumbnailUrl}
                  alt="Current profile photo"
                  className="w-20 md:w-52 lg:w-40 h-20 md:h-52 lg:h-40 rounded-half object-cover"
                />
              </div>
            )}

            <div className="flex flex-col mt-4 items-center w-full h-1/6 cursor-pointer">
              <div className="w-16 h-8 p-1 md:p-2 text-center border border-taro shadow-sm rounded font-medium text-xs text-truffle">
                <label htmlFor="avatarFile">Replace</label>
              </div>
              <input
                type="file"
                id="avatarFile"
                className="hidden"
                accept="image/*"
                onChange={e => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    setAvatarFile(file);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      if (reader.result) {
                        setThumbnailUrl(reader.result.toString());
                      }
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </div>
          </div>

          <div
            className={`flex flex-col justify-start items-start w-1/2 lg:w-3/5 h-96 p-2 md:p-5 ${rightBoxLeftMargin} border border-taro rounded-lg shadow bg-white font-medium text-xs md:text-sm text-truffle`}
          >
            <div className="flex flex-col w-full h-1/4 items-start">
              <label htmlFor="username">Username</label>
              <div className="h-2"></div>
              <div className="flex flex-row items-center w-full">
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={e => {
                    setUsername(e.target.value);
                    setErrorMsg('');
                  }}
                  className="w-3/5 lg:w-2/5 text-charcoal p-2 border border-taro shadow-sm rounded-md hover:border-charcoal"
                />
                <div className="w-5"></div>
                <p className="font-normal text-light">
                  Your unique handle on Flavrs
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full h-1/4 items-start">
              <label htmlFor="displayName" className="text-truffle">
                Display name
              </label>
              <div className="h-2"></div>
              <div className="flex flex-row items-center w-full">
                <input
                  type="text"
                  id="displayName"
                  value={displayName}
                  onChange={e => {
                    setDisplayName(e.target.value);
                    setErrorMsg('');
                  }}
                  className="w-3/5 lg:w-2/5 text-charcoal p-2 border border-taro shadow-sm rounded-md hover:border-charcoal"
                />
                <div className="w-5"></div>
                <p className="font-normal text-light">
                  Displayed on your profile page
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="h-2"></div>

        <div className="flex flex-row justify-start w-full">
          <div className={leftPageMarginWidth}></div>
          <div className="w-1/3 lg:w-1/5"></div>
          <button
            type="submit"
            disabled={isSaving}
            className={`w-36 h-12 ${rightBoxLeftMargin} bg-charcoal text-white ${buttonStyle}}`}
          >
            {isSaving ? 'Saving...' : 'Save changes'}
          </button>
        </div>
        <div className="h-2"></div>
        <div className="flex justify-center">
          <ErrorPanel error={errorMsg}></ErrorPanel>
        </div>
      </form>
    </div>
  );
}

export default Profile;
