import React from 'react';
import {LOGIN_ROUTE} from 'app/routes';
import {buttonStyle} from 'features/utilities/styles';
import {useNavigate} from 'react-router-dom';
import FlavrsHeader from './FlavrsHeader';

type EmailCheckPromptProps = {
  email: string;
  isPasswordReset?: boolean;
};
export function EmailCheckPrompt({
  email,
  isPasswordReset = false,
}: EmailCheckPromptProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="w-full min-h-screen bg-cover bg-marble-texture">
      <div className="w-full h-20"></div>
      <div className="flex flex-row w-full justify-center">
        <div className="w-4/5 md:2/3 lg:w-1/2 rounded-lg bg-white">
          <div className="flex flex-col justify-start items-center">
            <FlavrsHeader />
            <div className="w-full h-9"></div>
            <p className="w-1/2 md:w-1/3 text-charcoal text-center font-medium text-sm md:text-lg">
              We just sent an email to {email}.
            </p>
            <div className="w-full h-4"></div>
            {isPasswordReset ? (
              <p className="w-3/5 md:w-1/2 text-truffle text-center font-light text-sm md:text-lg">
                Please click the link from the email to change your password. It
                may take a few minutes for the email to reach your inbox. If you
                don&apos;t see it, please check your spam or junk mail folder.
                Please try again if you don&apos;t receive it within 10 minutes.
              </p>
            ) : (
              <p className="w-3/5 md:w-1/2 text-truffle text-center font-light text-sm md:text-lg">
                It may take a few minutes for the email to reach your inbox. If
                you don&apos;t see it, please check your spam or junk mail
                folder. Please try signing up again if you don&apos;t receive it
                within 10 minutes.
              </p>
            )}
            <button
              type="button"
              className={`w-1/3 md:w-1/6 h-12 mt-12 mb-28 ${buttonStyle} bg-white text-truffle`}
              onClick={() => navigate(LOGIN_ROUTE)}
            >
              Sign in now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
