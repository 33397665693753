import {InputMaybe} from '../../services/graphql/apolloTypes';
import {CSSObjectWithLabel, StylesConfig} from 'react-select';

export const headerStyle = 'font-semibold text-2xl text-charcoal';
export const inputBoxStyle =
  'border border-taro shadow-sm hover:border-shell focus:outline-none focus:border-charcoal\
   rounded-lg text-base font-normal placeholder-shell p-2 text-charcoal px-3 sm:text-sm sm:leading-6';
export const sectionHeaderInputBoxStyle = `${inputBoxStyle.replace(
  'shadow-sm',
  'shadow-none'
)} block w-full min-w-0 flex-1 rounded-none rounded-r-lg py-1.5`;
export const buttonStyle =
  'border border-taro shadow-sm rounded-md text-center font-medium text-sm md:text-base';
export const errorMessageStyle = 'text-tomato my-3 text-center';
export const errorStyle =
  'border border-red-500 bg-tomatotint focus:border-red-500';
// Define the common styles outside the configs
const commonSelectStyles = {
  indicatorSeparator: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: 'white',
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'truffle',
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: '#e3e0dc',
    borderRadius: '0.25rem',
  }),
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#969492',
    ':hover': {
      backgroundColor: '#afa79b',
      color: 'black',
    },
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    fontWeight: 400,
  }),
};

// used to style single option react-select component
export const selectStyle: StylesConfig<
  {
    value: InputMaybe<string> | undefined;
    label: InputMaybe<string> | undefined;
  },
  false
> = {
  ...commonSelectStyles,
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#969492',
    fontWeight: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  // cannot extract control into commonStyle because of state's incompatible type
  control: (base, state) => ({
    ...base,
    borderRadius: '0.5rem',
    borderColor: state.isFocused ? '#1c1512' : '#e3e0dc',
    height: '2.5rem',
    '&:hover': {
      borderColor: state.isFocused ? '#1c1512' : '#969492',
    },
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontWeight: 400,
  }),
};

// used to style multi-option react-select component
export const multiSelectStyle: StylesConfig<
  {value: string; label: string},
  true
> = {
  ...commonSelectStyles,
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#969492',
    fontWeight: 300,
  }),
  // cannot extract control into commonStyle because of state's incompatible type
  control: (base, state) => ({
    ...base,
    borderRadius: '0.5rem',
    borderColor: state.isFocused ? '#1c1512' : '#e3e0dc',
    height: '2.5rem',
    '&:hover': {
      borderColor: state.isFocused ? '#1c1512' : '#969492',
    },
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontWeight: 400,
  }),
  option: base => {
    return {
      ...base,
      whiteSpace: 'pre-wrap',
    };
  },
};

export const textInputBoxStyle = (error: string) =>
  `w-full h-14 pl-12 py-3 ${inputBoxStyle} ${
    error ? 'text-tomatoshade' : 'text-charcoal'
  }`;
