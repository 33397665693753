import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useLazyQuery} from '@apollo/client';
import {MeDocument} from '../graphql/apolloTypes';

import {MeQueryProfile} from '../graphql/apolloOperationTypes';
import {setStateChangeCallback} from 'commonreact';

export interface AuthData {
  state: AuthState;
  user: MeQueryProfile | null;
}

export enum AuthState {
  UNAUTHENTICATED,
  LOADING,
  AUTHENTICATED,
}

const DEFAULT_AUTH_DATA: AuthData = {
  state: AuthState.LOADING,
  user: null,
};

export const AuthContext = createContext(DEFAULT_AUTH_DATA);

export interface AuthProviderProps {
  children: ReactNode;
}
export function AuthProvider({children}: AuthProviderProps): JSX.Element {
  const [loadMe, {called, data, loading, error}] = useLazyQuery(MeDocument, {
    fetchPolicy: 'network-only',
  });
  const [emailVerifiedFromFirebase, setEmailVerifiedFromFirebase] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    setStateChangeCallback(async user => {
      if (user) {
        setEmailVerifiedFromFirebase(user.emailVerified);
      } else {
        setEmailVerifiedFromFirebase(null);
      }
      await loadMe();
    });
  }, [loadMe]);

  const authData: AuthData = useMemo(() => {
    const pending = !called ? true : loading;
    const user = data?.me?.isVerified ? data?.me : null;
    if (pending) {
      return {
        state: AuthState.LOADING,
        user: null,
      };
    } else if (user && !error && emailVerifiedFromFirebase) {
      return {
        state: AuthState.AUTHENTICATED,
        user,
      };
    } else {
      return {
        state: AuthState.UNAUTHENTICATED,
        user: null,
      };
    }
  }, [called, data, loading, error]);

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  );
}
