import {
  GetUploadedRecipeQuery,
  IngredientEntryInput,
  IngredientSectionInput,
  UploadRecipeInput,
} from '../../services/graphql/apolloTypes';
import {ElementOf} from 'commonreact';

/******************************************
 * GQL Input Types Helper
 ******************************************/

export type GetUploadedRecipeQueryUploadedRecipe = NonNullable<
  GetUploadedRecipeQuery['getUploadedRecipe']
>;

export type GetUploadedRecipeQueryIngredientSection = ElementOf<
  NonNullable<GetUploadedRecipeQueryUploadedRecipe['ingredientSections']>
>;

export type GetUploadedRecipeQueryInstructionSection = ElementOf<
  NonNullable<GetUploadedRecipeQueryUploadedRecipe['instructionSections']>
>;

export type GetUploadedRecipeQueryTag = ElementOf<
  NonNullable<GetUploadedRecipeQueryUploadedRecipe['tags']>
>;

export type ImageInput = Pick<
  UploadRecipeInput,
  'imageFile' | 'imageGcpPath' | 'imageMd5checksum'
>;

export type VideoInput = Pick<
  UploadRecipeInput,
  | 'videoGcpPath'
  | 'videoMd5checksum'
  | 'muxAssetId'
  | 'videoThumbnailTimeInSeconds'
>;

export type SimpleInput = Omit<
  UploadRecipeInput,
  keyof ImageInput | keyof VideoInput
>;

/******************************************
 * Form Types Helper
 ******************************************/

// This is used to manage extra displayFields for our input,
// as well as retrieve extra fields that are not part of the GQL input
export type UploadRecipeVideoForm = Omit<
  UploadRecipeInput,
  | 'status' // This is handled at submit time
  | 'yieldServings' // We need to be able to display '' in number form
  | 'prepTimeMins' // We need to be able to display '' in number form
  | 'cookTimeMins' // We need to be able to display '' in number form
  | 'totalTimeMins' // This is computed at submit time
  | 'ingredientSections' // We need displayName and quantityStr instead of quantity
> & {
  // TODO: Fix runtime type change (Formik rewrites with number)
  yieldServings: string;
  prepTimeMins: string;
  cookTimeMins: string;
  ingredientSections: ExtendedIngredientSectionInput[];

  // For display
  // imageUrl?: string;
  // videoPlaybackId?: string;

  // For pre-processing
  videoFile?: File;
};

export type ExtendedIngredientEntryInput = Omit<
  IngredientEntryInput,
  'quantity'
> & {
  displayName: string;
  quantityStr: string;
};

export type ExtendedIngredientSectionInput = Omit<
  IngredientSectionInput,
  'ingredients'
> & {
  ingredients: ExtendedIngredientEntryInput[];
};

/******************************************
 * UI State Helper
 ******************************************/

// The status of the upload, as seen by the creator
export enum DisplayStatus {
  NEW = 'New',
  DRAFT = 'Draft',
  IN_REVIEW = 'In Review',
  LIVE = 'Live',
  UNPUBLISHED = 'Unpublished',
}
