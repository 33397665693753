import React from 'react';

interface ErrorPanelProps {
  error: string;
}
function ErrorPanel({error}: ErrorPanelProps): JSX.Element {
  return (
    <>
      {error && (
        <div className="flex space-x-3 items-center justify-center w-7/10 md:w-1/2 text-sm font-medium text-center p-3 mb-5 rounded-lg bg-tomatotint text-tomatoshade">
          <img
            className="w-6 h-6"
            src="/exclamationicon.svg"
            alt="exclamation icon"
          />
          <p>{error}</p>
        </div>
      )}
    </>
  );
}
export default ErrorPanel;
