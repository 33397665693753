import React, {useState} from 'react';
import {
  InstructionSectionInput,
  ParsingTextType,
} from '../../services/graphql/apolloTypes';
import {default as InstructionSection} from './InstructionSection';
import ImportFreeTextModal from './ImportFreeTextModal';
import {UploadErrors} from 'features/utilities/errors';
import {headerStyle} from 'features/utilities/styles';

export const errorMessageStyle = 'text-tomato my-3 text-center';

type InstructionSectionsProps = {
  instructionSections: InstructionSectionInput[];
  setInstructionSections: (
    instructionSections: InstructionSectionInput[]
  ) => void;
  instructionFreeText: string;
  setInstructionFreeText: (text: string) => void;
  instructionsRef: React.RefObject<HTMLButtonElement>;
  uploadErrors: UploadErrors;
  setUploadErrors: React.Dispatch<React.SetStateAction<UploadErrors>>;
};

const InstructionSections: React.FC<InstructionSectionsProps> = ({
  instructionSections,
  setInstructionSections,
  instructionFreeText,
  setInstructionFreeText,
  instructionsRef,
  uploadErrors,
  setUploadErrors,
}) => {
  // Modal
  const [modalOpen, setModalOpen] = useState(false);

  // Instruction Sections
  const handleInstructionSectionNameChange = (index: number, value: string) => {
    const newInstructionSections = [...instructionSections];
    newInstructionSections[index].name = value;
    setInstructionSections(newInstructionSections);
  };

  const handleInstructionChange = (
    sectionIndex: number,
    instructionIndex: number,
    value: string
  ) => {
    const newInstructionSections = [...instructionSections];
    newInstructionSections[sectionIndex].instructions[instructionIndex] = value;
    setInstructionSections(newInstructionSections);

    // reset instruction error
    const errorKey = `instruction_${sectionIndex}_${instructionIndex}`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const addInstructionSection = () => {
    setInstructionSections([
      ...instructionSections,
      {name: '', instructions: []},
    ]);
  };

  const addInstruction = () => {
    const newInstructionSections = [...instructionSections];
    const sectionIndex = newInstructionSections.length - 1;
    newInstructionSections[sectionIndex].instructions = [
      ...newInstructionSections[sectionIndex].instructions,
      '',
    ];
    setInstructionSections(newInstructionSections);

    // reset instruction section error
    const errorKey = `instructionSection_${sectionIndex}_`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const addInstructionToStartOfSection = (sectionIndex: number) => {
    const newInstructionSections = [...instructionSections];
    newInstructionSections[sectionIndex].instructions = [
      '',
      ...newInstructionSections[sectionIndex].instructions,
    ];
    setInstructionSections(newInstructionSections);

    // reset instruction section error
    const errorKey = `instructionSection_${sectionIndex}_`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const deleteInstructionSection = (sectionIndex: number) => {
    const newInstructionSections = [...instructionSections];
    // move instructions in section to previous section, set section name to empty string if first section
    if (sectionIndex > 0) {
      newInstructionSections[sectionIndex - 1].instructions.push(
        ...newInstructionSections[sectionIndex].instructions
      );
      newInstructionSections.splice(sectionIndex, 1);
    } else {
      newInstructionSections[sectionIndex].name = '';
    }
    setInstructionSections(newInstructionSections);

    // reset instruction section error
    const errorKey = `instructionSection_${sectionIndex}_`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const deleteInstruction = (
    sectionIndex: number,
    instructionIndex: number
  ) => {
    const newInstructionSections = [...instructionSections];
    newInstructionSections[sectionIndex].instructions.splice(
      instructionIndex,
      1
    );
    setInstructionSections(newInstructionSections);

    // reset instruction error
    const errorKey = `instruction_${sectionIndex}_${instructionIndex}`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  // Parsing
  const parseInstructionSections = (parsedText: string[]) => {
    // if string is in brackets, set section header
    // else add to instructions
    const newInstructionSections: InstructionSectionInput[] = [];
    let currentSection: InstructionSectionInput = {
      name: '',
      instructions: [],
    };
    parsedText.forEach(line => {
      if (line.startsWith('[') && line.endsWith(']')) {
        if (currentSection.name !== '') {
          newInstructionSections.push(currentSection);
        }
        currentSection = {
          name: line.slice(1, line.length - 1),
          instructions: [],
        };
      } else {
        currentSection.instructions.push(line);
      }
    });
    newInstructionSections.push(currentSection);
    setInstructionSections(newInstructionSections);
  };

  return (
    <>
      <div className="flex justify-between items-center pb-2">
        <h3 className={headerStyle}>Directions</h3>
        <button
          ref={instructionsRef}
          type="button"
          onClick={() => setModalOpen(true)}
          className="text-persimmon focus:outline-none focus:ring-2 focus:ring-persimmon rounded-md p-2"
        >
          <div className="flex space-x-1">
            <img src="/upload/importfreetexticon.svg" alt="import icon" />
            <p>Import from plain text</p>
          </div>
        </button>
      </div>
      <p className="font-light pb-2">
        Use the preview box on the left to double check your directions are
        inputted accurately.
      </p>
      {Object.keys(uploadErrors).map((key, index) => {
        if (key.startsWith('instruction')) {
          return (
            <p key={index} className={errorMessageStyle}>
              {uploadErrors[key]}
            </p>
          );
        }
        return null;
      })}
      <ImportFreeTextModal
        header="Import Directions"
        description="Feel free to copy and paste directly from your recipe, don't worry about making any changes."
        placeholder="For the tangzhong&#10;To a pot on medium heat, add water, flour, and milk repeatedly stirring until a paste.&#10;Remove from the heat and let it sit aside.&#10;For the bread&#10;Heat milk until it reaches 95-100 degrees F."
        parsingTextType={ParsingTextType.Instructions}
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        onParsedText={parseInstructionSections}
        text={instructionFreeText}
        setText={setInstructionFreeText}
      />
      {instructionSections.map((instructionSection, sectionIndex) => (
        <InstructionSection
          key={`instruction-section-${sectionIndex}`}
          sectionIndex={sectionIndex}
          instructionSection={instructionSection}
          handleInstructionSectionNameChange={
            handleInstructionSectionNameChange
          }
          handleInstructionChange={handleInstructionChange}
          deleteInstructionSection={deleteInstructionSection}
          deleteInstruction={deleteInstruction}
          addInstructionToStartOfSection={addInstructionToStartOfSection}
          uploadErrors={uploadErrors}
        />
      ))}
      <button
        type="button"
        onClick={addInstruction}
        className="border border-dashed border-taro text-light font-normal py-2 rounded-md"
      >
        <div className="flex justify-center space-x-1" title="Add step">
          <img src="/addrowicon.svg" alt="add row icon"></img>
          <p>Add step</p>
        </div>
      </button>
      <button
        type="button"
        onClick={addInstructionSection}
        className="text-truffle font-medium mt-4"
      >
        <div
          className="flex justify-center space-x-1"
          title="Add section header"
        >
          <img src="/addheadericon.svg" alt="add header icon"></img>
          <p>Add section header</p>
        </div>
      </button>
    </>
  );
};

export default InstructionSections;
