import React, {useState} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import {useMutation} from '@apollo/client';
import {ResetPasswordDocument} from '../../services/graphql/apolloTypes';
import {LOGIN_ROUTE} from 'app/routes';
import {EnvelopeIcon} from '@heroicons/react/24/solid';
import FlavrsHeader from './FlavrsHeader';
import {
  textInputBoxStyle,
  buttonStyle,
  errorMessageStyle,
  errorStyle,
} from '../utilities/styles';
import ErrorPanel from '../utilities/ErrorPanel';
import * as Yup from 'yup';
import {EmailCheckPrompt} from './EmailCheckPrompt';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
});

interface ForgotPasswordInputs {
  email: string;
}

function ForgotPassword(): JSX.Element {
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [sendForgotPasswordEmail] = useMutation(ResetPasswordDocument);

  const inputBoxWidth = 'w-2/3 md:w-7/12';

  const submitEmail = async (
    {email}: ForgotPasswordInputs,
    {setSubmitting}: FormikHelpers<ForgotPasswordInputs>
  ): Promise<void> => {
    try {
      await sendForgotPasswordEmail({
        variables: {
          email: email,
        },
      });
      setEmailSent(true);
      setUserEmail(email);
    } catch (e) {
      setSubmitting(false);
      setError("Couldn't send an email. Please try again");
    }
  };

  if (emailSent) {
    return <EmailCheckPrompt email={userEmail} isPasswordReset />;
  }

  return (
    <div className="w-full min-h-screen bg-cover bg-marble-texture">
      <div className="w-full h-20"></div>
      <div className="flex flex-row w-full justify-center">
        <div className="w-4/5 md:2/3 lg:w-1/2 rounded-lg bg-white">
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={submitEmail}
          >
            {({values, errors, touched, handleChange, isSubmitting}) => (
              <Form className="flex flex-col justify-start items-center">
                <FlavrsHeader />
                <p
                  className={`${inputBoxWidth} text-charcoal text-center font-medium text-lg mt-9`}
                >
                  Enter your email below to request login details
                </p>
                <div className={`${inputBoxWidth} relative mt-3`}>
                  <input
                    id="email"
                    type="email"
                    value={values.email}
                    placeholder="Email address"
                    onChange={handleChange}
                    className={`${textInputBoxStyle(error)} ${
                      errors.email && errorStyle
                    }`}
                  />
                  <div className="absolute inset-y-0 left-4 flex items-center pl-0.5">
                    <EnvelopeIcon className="h-5 w-5 fill-shell" />
                  </div>
                </div>
                {errors.email && touched.email && (
                  <p className={errorMessageStyle}>{errors.email}</p>
                )}
                <button
                  type="submit"
                  disabled={values.email.length === 0 || isSubmitting}
                  className={`${inputBoxWidth} h-12 mt-4 ${
                    values.email.length === 0
                      ? 'bg-salt text-light'
                      : 'bg-charcoal text-white'
                  } ${buttonStyle}`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
                <div className="w-full h-6"></div>
                <p>
                  <a
                    href={LOGIN_ROUTE}
                    className="text-truffle text-center font-medium text-base"
                  >
                    Back
                  </a>
                </p>
                <div className="w-full h-5"></div>
                <ErrorPanel error={error}></ErrorPanel>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
