import React, {ReactNode} from 'react';
interface ButtonDefaultProps {
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}

function ButtonDefault({
  onClick = () => {},
  className = '', // Only use it for placement
  children,
}: ButtonDefaultProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`py-2 px-3 bg-white text-truffle border border-taro shadow-sm rounded-md text-center font-normal text-sm ${className}`}
    >
      {children}
    </button>
  );
}

export default ButtonDefault;
