import React, {createContext, ReactNode} from 'react';
import {useApolloAutocomplete, ApolloAutocompleteProvider} from 'commonreact';
import {
  IngredientsQueryIngredient,
  TagsQueryTag,
} from '../../services/graphql/apolloOperationTypes';
import {
  IngredientsDocument,
  IngredientsQuery,
  TagsDocument,
  TagsQuery,
} from '../../services/graphql/apolloTypes';

function defaultProvider<T>(): ApolloAutocompleteProvider<T> {
  return {
    items: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setQuery: () => {},
    error: undefined,
    loading: false,
    hasMinLength: false,
  };
}

// Ingredients autocomplete.
export const IngredientsContext =
  createContext(defaultProvider<IngredientsQueryIngredient>());
export function IngredientsProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const ingredientsProvider = useApolloAutocomplete(
    IngredientsDocument,
    search => ({search, searchConditions: {limit: 2000}}), // large limit to get all ingredients
    (data: IngredientsQuery) => data.ingredients.ingredients
  );

  return (
    <IngredientsContext.Provider value={ingredientsProvider}>
      {children}
    </IngredientsContext.Provider>
  );
}

// Tags autocomplete.
export const TagsContext = createContext(defaultProvider<TagsQueryTag>());
export function TagsProvider({children}: {children: ReactNode}): JSX.Element {
  const tagsProvider = useApolloAutocomplete(
    TagsDocument,
    search => ({search}),
    (data: TagsQuery) => data.tags
  );

  return (
    <TagsContext.Provider value={tagsProvider}>{children}</TagsContext.Provider>
  );
}
