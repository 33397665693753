import React, {useContext, useState, useEffect} from 'react';
import {AuthError} from 'firebase/auth';
import {authErrorMessages} from '../utilities/errors';
import {Navigate, Location, useNavigate, useLocation} from 'react-router-dom';
import {signInWithEmailAndPassword} from 'commonreact';
import {AuthContext, AuthState} from '../../services/auth/AuthProvider';
import {
  SetEmailVerifiedDocument,
  MeDocument,
} from '../../services/graphql/apolloTypes';
import {MAIN_ROUTE, SIGNUP_ROUTE, FORGOT_PASSWORD_ROUTE} from 'app/routes';
import {Form, Formik, FormikHelpers, useFormikContext} from 'formik';
import {useMutation, useLazyQuery} from '@apollo/client';
import {EyeIcon, EyeSlashIcon, EnvelopeIcon} from '@heroicons/react/24/solid';
import * as Yup from 'yup';
import FlavrsHeader from './FlavrsHeader';
import {
  textInputBoxStyle,
  buttonStyle,
  errorStyle,
  errorMessageStyle,
} from '../utilities/styles';
import ErrorPanel from '../utilities/ErrorPanel';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Your password must be at least 6 characters')
    .required('Password is required'),
});

interface LoginInputs {
  email: string;
  password: string;
}

interface NavigationState {
  from: Location;
}

function Login(): JSX.Element {
  const {state} = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [hasInput, setHasInput] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [error, setError] = useState('');
  const [loadMe] = useLazyQuery(MeDocument, {
    fetchPolicy: 'network-only',
  });
  const [setEmailVerified] = useMutation(SetEmailVerifiedDocument);

  const inputBoxWidth = 'w-2/3 md:w-1/2';
  const iconStyle = 'h-5 w-5 fill-shell';

  if (state === AuthState.AUTHENTICATED) {
    return <Navigate to={MAIN_ROUTE} replace />;
  }

  const navigationState = location.state as NavigationState | undefined;
  const from = navigationState?.from || '/';
  const navigateToNextPage = () => navigate(from, {replace: true});

  const submitEmailAndPassword = async (
    {email, password}: LoginInputs,
    {setSubmitting}: FormikHelpers<LoginInputs>
  ): Promise<void> => {
    try {
      const user = await signInWithEmailAndPassword(email, password);
      if (user.email && user.emailVerified) {
        const userProfile = await loadMe();
        if (userProfile?.data?.me?.emailVerified !== user.emailVerified) {
          await setEmailVerified({
            variables: {
              email: user.email,
            },
          });
        }
        navigateToNextPage();
      } else {
        setError(
          "Email not verified. Please check your inbox for a verification email or try 'Sign up' again. The verification email might take a few minutes to arrive."
        );
      }
    } catch (e) {
      const errorCode = (e as AuthError)?.code;
      setError(authErrorMessages[errorCode] || errorCode);
    } finally {
      setSubmitting(false);
    }
  };

  const FormObserver: React.FC = () => {
    const {values} = useFormikContext<LoginInputs>();
    useEffect(() => {
      setHasInput(!!values.email || !!values.password);
      if (inputChanged) {
        setError('');
        setInputChanged(false);
      }
    }, [inputChanged]);
    return null;
  };

  return (
    <div className="w-full min-h-screen bg-marble-texture bg-cover">
      <div className="w-full h-20"></div>
      <div className="flex flex-row w-full justify-center">
        <div className="w-4/5 md:2/3 lg:w-1/2 shadow rounded-lg bg-white">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={submitEmailAndPassword}
          >
            {({values, errors, touched, handleChange, isSubmitting}) => (
              <Form className="flex flex-col justify-start items-center">
                <FormObserver />
                <FlavrsHeader />
                <div className={`${inputBoxWidth} relative mt-9`}>
                  <input
                    id="email"
                    type="email"
                    value={values.email}
                    placeholder="Email address"
                    onChange={e => {
                      handleChange(e);
                      setInputChanged(true);
                    }}
                    className={`${textInputBoxStyle(error)} ${
                      errors.email && errorStyle
                    }`}
                  />
                  <div className="absolute inset-y-0 left-4 flex items-center pl-0.5">
                    <EnvelopeIcon className={iconStyle} />
                  </div>
                </div>
                {errors.email && touched.email && (
                  <p className={errorMessageStyle}>{errors.email}</p>
                )}
                <div className={`${inputBoxWidth} relative mt-4`}>
                  <input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    placeholder="Password"
                    onChange={e => {
                      handleChange(e);
                      setInputChanged(true);
                    }}
                    className={`${textInputBoxStyle(error)} ${
                      errors.password && errorStyle
                    }`}
                  />
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 left-4 flex items-center pl-0.5 cursor-pointer"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className={iconStyle} />
                    ) : (
                      <EyeIcon className={iconStyle} />
                    )}
                  </div>
                </div>
                {errors.password && touched.password && (
                  <p className={errorMessageStyle}>{errors.password}</p>
                )}
                <button
                  type="submit"
                  disabled={!hasInput || isSubmitting}
                  className={`${inputBoxWidth} h-12 mt-4 ${
                    hasInput ? 'bg-charcoal text-white' : 'bg-salt text-light'
                  } ${buttonStyle}`}
                >
                  {isSubmitting ? 'Signing in...' : 'Sign in'}
                </button>
                <div className="w-full h-6"></div>
                <p>
                  <a
                    href={FORGOT_PASSWORD_ROUTE}
                    className="text-truffle text-center font-medium text-base"
                  >
                    Forgot login details?
                  </a>
                </p>
                <div className="w-full h-16"></div>
                <p className="text-truffle text-center font-medium text-base">
                  First time here?{' '}
                  <a href={SIGNUP_ROUTE} className="text-persimmon">
                    Sign up
                  </a>
                </p>
                <div className="w-full h-5"></div>
                <ErrorPanel error={error}></ErrorPanel>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
