import {ApolloClient, InMemoryCache, from} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client';

import {APOLLO_ENABLE_DEVTOOLS, APOLLO_SERVER_URL} from '../../app/config';
import {getIdToken, logoutCallback} from 'commonreact';

import {StrictTypedTypePolicies} from './apolloHelpers';
import {UploadedRecipeList} from './apolloTypes';

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      getUploadedRecipes: {
        keyArgs: [
          'query',
          'filter',
          ['creatorId', 'status'],
          'searchConditions',
          ['limit'],
        ],
        merge(
          existing: UploadedRecipeList,
          incoming: UploadedRecipeList
        ): UploadedRecipeList {
          return {
            totalCount: incoming.totalCount,
            hasMore: incoming.hasMore,
            cursor: incoming.cursor,
            uploadedRecipes: [
              ...(existing ? existing.uploadedRecipes : []),
              ...incoming.uploadedRecipes,
            ],
          };
        },
      },
    },
  },
};

const cache = new InMemoryCache({
  typePolicies,
});

/*
 * Authentication
 */

const authLink = setContext(async (_, prevContext) => {
  const {headers} = prevContext;
  const token = await getIdToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const logoutLink = onError(({graphQLErrors}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message}) => {
      if (message === 'Unauthenticated') {
        logoutCallback();
      }
    });
  }
});

// Needed to allow for image uploads.
const httpLink = createUploadLink({
  uri: APOLLO_SERVER_URL,
});

const client = new ApolloClient({
  link: from([authLink, logoutLink, httpLink]),
  cache,
  connectToDevTools: APOLLO_ENABLE_DEVTOOLS,
  name: 'CK',
});

export default client;
