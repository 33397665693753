import * as Sentry from '@sentry/react';
import {SENTRY_ENVIRONMENT, SENTRY_DSN} from './app/config';

import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './app/App';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);
