import React, {useState, memo, useEffect} from 'react';
import {ErrorCode, FileRejection, useDropzone} from 'react-dropzone';
import {ExclamationCircleIcon} from '@heroicons/react/24/solid';
import {errorMessageStyle, errorStyle} from 'features/utilities/styles';
import {ReloadableMuxPlayer} from 'components/ReloadableMuxPlayer';
import ButtonPrimary from 'components/ButtonPrimary';
import ButtonDefault from 'components/ButtonDefault';

interface VideoDropzoneProps {
  existingVideoPlaybackId: string | null | undefined;
  pendingFile: File | null | undefined;
  onFileDrop: (file: File | null) => void;
  uploadErrorMessage: string;
  clearUploadErrorMessage: () => void;
}

function VideoDropzone({
  existingVideoPlaybackId,
  pendingFile,
  onFileDrop,
  uploadErrorMessage,
  clearUploadErrorMessage,
}: VideoDropzoneProps) {
  const [dropErrorMessage, setDropErrorMessage] = useState('');
  const [fileName, setFileName] = useState('');

  // Keep a hold on any preview url
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  useEffect(() => {
    if (previewUrl) {
      // Revoke any old url first
      // TODO might need to call explicitly on pendingFile destructtion?
      URL.revokeObjectURL(previewUrl);
    }
    if (pendingFile) {
      setPreviewUrl(URL.createObjectURL(pendingFile));
    }
  }, [pendingFile]);

  const processVideo = async (droppedFile: File) => {
    // Encode special characters in file name to avoid URL encoding issues
    const encodedFileName = encodeURIComponent(droppedFile.name);
    return new File([droppedFile], encodedFileName, {
      type: droppedFile.type,
    });
  };

  const onDropAccepted = async (acceptedFiles: File[]) => {
    const droppedFile: File = acceptedFiles[0]; // Guaranteed to exist
    const processedFile = await processVideo(droppedFile);
    onFileDrop(processedFile);
    setFileName(droppedFile.name);
    clearUploadErrorMessage();
    setDropErrorMessage('');
  };

  const onDropRejected = async (fileRejections: FileRejection[]) => {
    clearUploadErrorMessage();
    switch (fileRejections[0].errors[0].code) {
      case ErrorCode.FileTooLarge:
        setDropErrorMessage(
          'The file size is too large. The maximum size is 500MB.'
        );
        break;
      case ErrorCode.FileInvalidType:
        setDropErrorMessage('Invalid file type');
        break;
      default:
        setDropErrorMessage('Unknown error');
    }
  };

  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    maxSize: 1_024 * 1_024 * 1_024, // 1GiB
    accept: {'video/*': []},
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDropRejected,
  });

  const dottedBorderStyle =
    'grow p-3 border-2 rounded-lg border-dashed border-taro flex flex-col justify-center items-center gap-2';

  const switchState = (isDragActive: boolean, dropErrorMessage: string) => {
    if (isDragActive) {
      return (
        <div className={`${dottedBorderStyle} bg-cream`}>
          <img src="/upload/videouploadicon.svg" alt="video upload icon" />
          <p className="text-center text-truffle font-semibold">
            Drop video file here
          </p>
        </div>
      );
    } else if (
      dropErrorMessage &&
      !uploadErrorMessage /* uploadErrorMessage takes precedence */
    ) {
      // Error State
      return (
        <div className={dottedBorderStyle}>
          <ExclamationCircleIcon className="h-10 w-10 text-tomato" />
          <p className="text-center text-tomato">{dropErrorMessage}</p>
          <p className="text-xs">Please try again</p>
          <ButtonPrimary onClick={open} className="mt-2">
            Browse File
          </ButtonPrimary>
        </div>
      );
    } else if (previewUrl || existingVideoPlaybackId) {
      // Preview State
      // TODO: split into existing vs preview
      // TODO: add video info (dimensions, size)
      return (
        <div className="grow flex flex-row xl:flex-col justify-center xl:justify-start items-center gap-x-8 gap-y-2">
          {previewUrl ? (
            <video
              className="aspect-[9/16] w-64 xl:w-full h-full object-cover"
              src={previewUrl}
              controls
            />
          ) : (
            <ReloadableMuxPlayer playbackId={existingVideoPlaybackId || ''} />
          )}
          <ButtonDefault onClick={open} className="mt-2 xl:hidden">
            Change
          </ButtonDefault>
          <div className="hidden xl:flex justify-between w-full text-sm font-normal">
            <p className="text-shell">
              {fileName || 'Your previously uploaded video'}
            </p>
            <button type="button" className="text-persimmon" onClick={open}>
              Change
            </button>
          </div>
        </div>
      );
    } else {
      // Empty State
      return (
        <div
          className={`${dottedBorderStyle} ${uploadErrorMessage && errorStyle}`}
        >
          <img src="/upload/videouploadicon.svg" alt="video upload icon" />
          <p>
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs font-semibold">
            Upload .MOV, .MP4, .M4V. Less than 500MB
          </p>
          <ButtonPrimary onClick={open} className="mt-2">
            Browse File
          </ButtonPrimary>
          {uploadErrorMessage && (
            <p className={errorMessageStyle}>{uploadErrorMessage}</p>
          )}
        </div>
      );
    }
  };

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps({
        className:
          'grow min-h-[22rem] overflow-hidden flex flex-col justify-stretch',
      })}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      {switchState(isDragActive, dropErrorMessage)}
    </div>
  );
}

// only re-render if props change
export default memo(VideoDropzone);
