import React, {useState, memo, useEffect} from 'react';
import {ErrorCode, FileRejection, useDropzone} from 'react-dropzone';
import {ExclamationCircleIcon} from '@heroicons/react/24/solid';
import heic2any from 'heic2any';
import {errorMessageStyle} from 'features/utilities/styles';
import ButtonPrimary from 'components/ButtonPrimary';

const isFileSupportedImage = async (file: File) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const magicNumbers = new Uint8Array(reader.result as ArrayBuffer);

      // PNG files start with 89 50 4E 47
      const isPng =
        magicNumbers[0] === 0x89 &&
        magicNumbers[1] === 0x50 &&
        magicNumbers[2] === 0x4e &&
        magicNumbers[3] === 0x47;

      // JPEG files start with FF D8
      const isJpeg = magicNumbers[0] === 0xff && magicNumbers[1] === 0xd8;

      // According to https://developers.google.com/speed/webp/docs/riff_container,
      // webp files start with 0x52, 0x49, 0x46, 0x46, 4 bytes for file size,
      // and then 0x57, 0x45, 0x42, 0x50.
      const isWebp =
        magicNumbers[0] === 0x52 &&
        magicNumbers[1] === 0x49 &&
        magicNumbers[2] === 0x46 &&
        magicNumbers[3] === 0x46 &&
        magicNumbers[8] === 0x57 &&
        magicNumbers[9] === 0x45 &&
        magicNumbers[10] === 0x42 &&
        magicNumbers[11] === 0x50;

      resolve(isJpeg || isPng || isWebp);
    };
    // We only need to read the first 12 bytes (magic numbers) of the file
    reader.readAsArrayBuffer(file.slice(0, 12));
  });
};

interface ImageDropzoneProps {
  existingUrl: string | null | undefined;
  pendingFile: File | null | undefined;
  onFileDrop: (file: File | null) => void;
  uploadErrorMessage: string;
  clearUploadErrorMessage: () => void;
}
function ImageDropzone({
  existingUrl,
  pendingFile,
  onFileDrop,
  uploadErrorMessage,
  clearUploadErrorMessage,
}: ImageDropzoneProps) {
  const [dropErrorMessage, setDropErrorMessage] = useState('');
  const [fileName, setFileName] = useState('');

  // Keep a hold on any preview url
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  useEffect(() => {
    if (previewUrl) {
      // Revoke any old url first
      // TODO might need to call explicitly on pendingFile destructtion?
      URL.revokeObjectURL(previewUrl);
    }
    if (pendingFile) {
      setPreviewUrl(URL.createObjectURL(pendingFile));
    }
  }, [pendingFile]);

  const processImage = async (droppedFile: File) => {
    // Encode special characters in file name to avoid URL encoding issues
    const encodedFileName = encodeURIComponent(droppedFile.name);
    const renamedFile = new File([droppedFile], encodedFileName, {
      type: droppedFile.type,
    });

    const isSupportedImage = await isFileSupportedImage(renamedFile);
    if (isSupportedImage) {
      return renamedFile;
    } else {
      try {
        const convertedBlob = await heic2any({
          blob: renamedFile,
          toType: 'image/jpeg',
          quality: 0.7,
        });
        const convertedFile = new File(
          [convertedBlob as BlobPart],
          renamedFile.name.replace(/\..+$/, '.jpg'),
          {
            type: 'image/jpeg',
          }
        );
        return convertedFile;
      } catch (error) {
        throw new Error('Error converting HEIC/HEIF image');
      }
    }
  };

  const onDropAccepted = async (acceptedFiles: File[]) => {
    const droppedFile: File = acceptedFiles[0]; // Guaranteed to exist
    const processedFile = await processImage(droppedFile);
    onFileDrop(processedFile);
    setFileName(droppedFile.name);
    clearUploadErrorMessage();
    setDropErrorMessage('');
  };

  const onDropRejected = async (fileRejections: FileRejection[]) => {
    clearUploadErrorMessage();
    switch (fileRejections[0].errors[0].code) {
      case ErrorCode.FileTooLarge:
        setDropErrorMessage(
          'The file size is too large. The maximum size is 5MB.'
        );
        break;
      case ErrorCode.FileInvalidType:
        setDropErrorMessage('Invalid file type');
        break;
      default:
        setDropErrorMessage('Weid error');
    }
  };

  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    maxSize: 5 * 1_024 * 1_024, // 5mb
    accept: {'image/*': []},
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDropRejected,
  });

  const switchState = (isDragActive: boolean, dropErrorMessage: string) => {
    const previewImageUrl = previewUrl || existingUrl;
    if (isDragActive) {
      // Drag State
      return (
        <div className="h-56 text-center font-normal text-truffle border border-taro border-dashed rounded py-5 bg-cream">
          <div className="flex flex-col justify-center items-center">
            <img src="/upload/imageuploadicon.svg" alt="image upload icon" />
            <p className="font-semibold">Drop image file here ...</p>
          </div>
        </div>
      );
    } else if (dropErrorMessage) {
      // Error State
      return (
        <div className="flex flex-col items-center mx-3">
          <ExclamationCircleIcon className="h-10 w-10 text-tomato" />
          <p className="pt-2 text-tomato">{dropErrorMessage}</p>
          <p className="text-xs pt-2">Please try again</p>
          <ButtonPrimary onClick={open} className="mt-4">
            Browse File
          </ButtonPrimary>
        </div>
      );
    } else if (previewImageUrl) {
      // Preview State
      // TODO: split into existing vs preview
      // TODO: add image info (dimensions, size)
      return (
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-x-8">
          <img
            className="h-56 w-32 rounded object-cover"
            src={previewImageUrl}
            alt="preview"
          />
          {fileName && <p>{fileName}</p>}
          <button
            type="button"
            onClick={open}
            className="py-2 px-3 bg-white border border-taro shadow rounded-md text-center text-truffle"
          >
            Change
          </button>
        </div>
      );
    } else {
      // Empty State
      return (
        <div className="flex justify-center space-x-4">
          <img
            src="/cover-image-placeholder.svg"
            alt="cover image placeholder"
          ></img>
          <div className="flex flex-col justify-center items-center">
            <img src="/upload/imageuploadicon.svg" alt="image upload icon" />
            <p className="pt-2">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs font-semibold pt-2">
              Upload JPEG, PNG, WEBP or HEIC. Less than 5MB
            </p>
            <p className="text-xs font-semibold">
              Recommended size: 900 x 1600px
            </p>

            <button
              type="button"
              onClick={open}
              className="h-10 mt-4 px-3 bg-yolk text-white border shadow-sm rounded-md text-center font-normal text-sm"
            >
              Browse File
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps({
        className: 'h-full',
      })}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      {uploadErrorMessage && (
        <div className={errorMessageStyle}>{uploadErrorMessage}</div>
      )}
      {switchState(isDragActive, dropErrorMessage)}
    </div>
  );
}

// only re-render if props change
export default memo(ImageDropzone);
