import {
  IngredientEntryInput,
  IngredientSectionInput,
  InstructionSectionInput,
  RecipeTagEntryInput,
  RecipeTagType,
  TernaryState,
  UploadedRecipeStatus,
} from '../../services/graphql/apolloTypes';
import {TagsQueryTag} from '../../services/graphql/apolloOperationTypes';
import {UploadErrors} from 'features/utilities/errors';
import numericQuantity from 'numeric-quantity';
import {
  ExtendedIngredientSectionInput,
  GetUploadedRecipeQueryIngredientSection,
  GetUploadedRecipeQueryInstructionSection,
  GetUploadedRecipeQueryTag,
  GetUploadedRecipeQueryUploadedRecipe,
  UploadRecipeVideoForm,
} from './upload-recipe-types';

const MAX_TOTAL_TIME_IN_MINUTES = 50 * 60;
const MIN_TOTAL_TIME_IN_MINUTES = 1;
const MIN_TAG_COUNT = 2;

/***************************************************************
 * GetUploadedRecipeQuery types -> UploadedRecipeVideoForm types
 ***************************************************************/

/**
 * Tranforms a UploadedRecipe response into a usable input object.
 */
export const parseToUplaodRecipeVideoForm = (
  r: GetUploadedRecipeQueryUploadedRecipe | undefined
): UploadRecipeVideoForm => {
  if (r) {
    return {
      id: r.id,
      title: r.title,
      description: r.description || '',
      yieldServings: parseToDisplayableNumber(r.yieldServings),
      prepTimeMins: parseToDisplayableNumber(r.prepTimeMins),
      cookTimeMins: parseToDisplayableNumber(r.cookTimeMins),
      ingredientSections: parseToIngredientSectionsForm(r.ingredientSections),
      instructionSections: parseToInstructionSectionsForm(
        r.instructionSections
      ),
      recipeTagEntries: parseToTagsForm(r.tags),

      // Image Fields
      imageFile: undefined, // Only exist if creator upload new asset
      imageGcpPath: r.imageGcpPath,
      imageMd5checksum: r.imageMd5checksum,

      // Video Fields
      videoFile: undefined, // Only exist if creator upload new asset
      videoGcpPath: r.videoGcpPath,
      muxAssetId: r.muxAssetId,
      videoMd5checksum: r.videoMd5checksum,
      // videoThumbnailTimeInSeconds: Float TODO: unused for now
    };
  } else {
    return {
      id: undefined,
      title: '',
      description: '',
      yieldServings: '',
      prepTimeMins: '',
      cookTimeMins: '',
      ingredientSections: [
        {
          name: '',
          // Prepare 1 pre-filled entry
          ingredients: [
            {
              ingredientId: '',
              preparation: '',
              rawIngredientLine: '',
              unit: '',
              quantityStr: '',
              displayName: '',
            },
          ],
        },
      ],
      instructionSections: [
        // Prepare 1 pre-filled entry
        {name: '', instructions: ['']},
      ],
      recipeTagEntries: [],

      // Image Fields, all fields are unset
      imageFile: undefined,
      imageGcpPath: undefined,
      imageMd5checksum: undefined,

      // Video Fields, all fields are unset
      videoFile: undefined,
      videoGcpPath: undefined,
      muxAssetId: undefined,
      videoMd5checksum: undefined,
      // videoThumbnailTimeInSeconds: Float TODO: unused for now
    };
  }
};

export const parseToIngredientSectionsForm = (
  ingredientSections: GetUploadedRecipeQueryIngredientSection[]
) =>
  ingredientSections.map(ingredientSection => ({
    name: ingredientSection.name,
    ingredients: ingredientSection.ingredients.map(ingredient => ({
      ingredientId: ingredient?.ingredient?.id,
      unit: ingredient.unit,
      preparation: ingredient.preparation,
      rawIngredientLine: ingredient.rawIngredientLine,
      displayName:
        ingredient?.ingredient?.displayName ||
        ingredient.rawIngredientLine ||
        '',
      quantityStr: ingredient.quantityStr,
    })),
  }));

export const parseToInstructionSectionsForm = (
  instructionSections: GetUploadedRecipeQueryInstructionSection[]
) =>
  instructionSections.map(instructionSection => ({
    name: instructionSection.name,
    instructions: instructionSection.instructions,
  }));

export const parseToTagsForm = (tags: GetUploadedRecipeQueryTag[]) =>
  tags.map(tag => ({
    tagId: tag.id,
    tagValue: tag.value,
  }));

const parseToDisplayableNumber = (n: number | undefined | null) =>
  n ? n.toString() : '';

/***************************************************************
 * UploadedRecipeVideoForm transformations
 ***************************************************************/

/*
 * Populate recipeTagEntries with unknown ternary tags if not already populated
 */
export const refreshTags = (
  recipeTagEntries: RecipeTagEntryInput[],
  tags: TagsQueryTag[]
) => {
  const unknownAllergyTags = tags
    .filter(
      tag =>
        tag.ternaryState === TernaryState.Unknown &&
        tag.types.includes(RecipeTagType.Allergy)
    )
    .map(tag => ({tagId: tag.id, tagValue: tag.value}));

  const newRecipeTagEntries = [...recipeTagEntries];
  unknownAllergyTags.forEach(tag => {
    if (!recipeTagEntries.some(entry => entry.tagValue === tag.tagValue)) {
      newRecipeTagEntries.push(tag);
    }
  });
  return withFixedOrdering(newRecipeTagEntries);
};

/**
 * Ensure our recipeTagEntries, which is semantically a record,
 * has a fixed order for deep equality comparison
 */
export const withFixedOrdering = (recipeTagEntries: RecipeTagEntryInput[]) =>
  recipeTagEntries.sort((a, b) =>
    a.tagId === b.tagId ? 0 : a.tagId < b.tagId ? -1 : 1
  );

/***************************************************************
 * UploadedRecipeVideoForm types -> UploadedRecipeInput types
 ***************************************************************/

export const parseToTotalTimeInput = (
  prepTimeMins: string,
  cookTimeMins: string
) => {
  return (
    (parseInt(prepTimeMins) || 0) + (parseInt(cookTimeMins) || 0) || undefined
  );
};

export const parseToIngredientSectionInput = (
  ingredientSections: ExtendedIngredientSectionInput[],
  targetStatus: UploadedRecipeStatus
) => {
  // Format ingredient sections for upload
  const errors: UploadErrors = {};
  const ingredientSectionsInput: IngredientSectionInput[] = [];
  ingredientSections.forEach((ingredientSection, sectionIndex) => {
    const ingredientSectionInput: IngredientSectionInput = {
      name: ingredientSection.name,
      ingredients: [],
    };
    ingredientSection.ingredients.forEach((ingredient, ingredientIndex) => {
      // if empty ingredient, throw error
      if (
        !ingredient.ingredientId &&
        !ingredient.rawIngredientLine &&
        targetStatus === UploadedRecipeStatus.InReview
      ) {
        errors[`ingredient_${sectionIndex}_${ingredientIndex}`] =
          'Ingredient cannot be empty.';
      }

      // quantityStr error handling
      const quantity = ingredient.quantityStr
        ? numericQuantity(ingredient.quantityStr)
        : 0;
      if (isNaN(quantity) || quantity < 0) {
        errors[`quantityStr_${sectionIndex}_${ingredientIndex}`] =
          `Quantity "${ingredient.quantityStr}" is not a valid number.`;
      }
      // eslint-disable-next-line
      const {displayName: _, quantityStr: __, ...ing} = ingredient; // Exclude DisplayName & quantityStr
      // create IngredientEntryInput with quantity
      const ingredientEntryInput: IngredientEntryInput = {
        ...ing,
        quantity,
      };
      ingredientSectionInput.ingredients.push(ingredientEntryInput);
    });
    if (
      ingredientSectionInput.ingredients.length === 0 &&
      targetStatus === UploadedRecipeStatus.InReview
    ) {
      errors[`ingredientSection_${sectionIndex}_`] =
        'Ingredient section must contain at least one ingredient.';
    } else {
      ingredientSectionsInput.push(ingredientSectionInput);
    }
  });

  return {
    ingredientSectionsInput,
    errors,
  };
};

// Main Validation. Need to be managed via Formik or zod instead of manual validation
// TODO: we also need to validate (frontend and backend) that if there are more than 1 section
// they should both have titles;
export const validateForm = (
  values: UploadRecipeVideoForm,
  targetStatus: UploadedRecipeStatus,
  allTags: TagsQueryTag[]
) => {
  const errors: UploadErrors = {};

  if (!values.title || values.title.length > 60) {
    errors.title = 'Title is required and must be at most 60 characters.';
  }

  // if saving a draft, don't validate the rest of the form
  if (targetStatus === UploadedRecipeStatus.InReview) {
    if (!values.yieldServings || Number(values.yieldServings) <= 0) {
      errors.yieldServings =
        'Yield servings is required and must be greater than zero.';
    }
    if (!values.videoFile && !values.muxAssetId) {
      errors.videoFile =
        'Please select a video file before submitting the form.';
    }

    // Validate times
    const cookTimeMins = parseInt(values.cookTimeMins);
    const prepTimeMins = parseInt(values.prepTimeMins);
    if (
      cookTimeMins &&
      (cookTimeMins < MIN_TOTAL_TIME_IN_MINUTES ||
        cookTimeMins > MAX_TOTAL_TIME_IN_MINUTES)
    ) {
      errors.cookTimeMins = `Cook time must be between ${MIN_TOTAL_TIME_IN_MINUTES} and ${MAX_TOTAL_TIME_IN_MINUTES} minutes.`;
    }
    if (
      prepTimeMins &&
      (prepTimeMins < MIN_TOTAL_TIME_IN_MINUTES ||
        prepTimeMins > MAX_TOTAL_TIME_IN_MINUTES)
    ) {
      errors.prepTimeMins = `Prep time must be between ${MIN_TOTAL_TIME_IN_MINUTES} and ${MAX_TOTAL_TIME_IN_MINUTES} minutes.`;
    }
    if (!prepTimeMins && !cookTimeMins) {
      errors.totalTimeMins = "Cook time and prep time can't both be absent.";
    }

    // Validate tags
    const unknownAllergyTags = allTags
      .filter(
        tag =>
          tag.ternaryState === TernaryState.Unknown &&
          tag.types.includes(RecipeTagType.Allergy)
      )
      .map(tag => tag.id);
    const tags = values.recipeTagEntries.filter(
      tag => !unknownAllergyTags.includes(tag.tagId)
    );
    if (tags.length < MIN_TAG_COUNT) {
      errors.tags = `This video should have at least ${MIN_TAG_COUNT} attributes.`;
    }

    values.instructionSections.forEach((instructionSection, sectionIndex) => {
      // throw error if instruction section is empty
      if (instructionSection.instructions.length === 0) {
        errors[`instructionSection_${sectionIndex}_`] =
          'Instruction section must contain at least one instruction.';
      }
      // throw error if instruction is empty
      instructionSection.instructions.forEach(
        (instruction, instructionIndex) => {
          if (instruction.trim().length === 0) {
            errors[`instruction_${sectionIndex}_${instructionIndex}`] =
              'Instruction cannot be empty.';
          }
        }
      );
    });
  }

  const {errors: ingredientSectionErrors} = parseToIngredientSectionInput(
    values.ingredientSections,
    targetStatus
  );
  const validationErrors = {...errors, ...ingredientSectionErrors};
  return Object.keys(validationErrors).length > 0 ? validationErrors : null;
};

const _pickText = (converted: string, freeText: string) => {
  return converted ? converted : freeText;
};

export const getIngredientText = (
  ingredientSections: ExtendedIngredientSectionInput[],
  freeText: string
) => {
  // Convert ingredientSections to plain text.
  const converted = ingredientSections
    .map(
      section =>
        `${section.name}\n` +
        section.ingredients
          .map(
            ingredient =>
              `${ingredient.quantityStr} ${ingredient.unit} ${
                ingredient.ingredientId
                  ? ingredient.displayName
                  : ingredient.rawIngredientLine
              }${ingredient.preparation && ' ' + ingredient.preparation}`
          )
          .join('\n')
    )
    .join('\n')
    .trim();
  return _pickText(converted, freeText);
};

export const getInstructionText = (
  instructionSections: InstructionSectionInput[],
  freeText: string
) => {
  const converted = instructionSections
    .map(
      section =>
        `${section.name}\n` +
        section.instructions.map(instruction => `${instruction}`).join('\n')
    )
    .join('\n')
    .trim();

  return _pickText(converted, freeText);
};
