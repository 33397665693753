import React, {ReactNode} from 'react';
interface ButtonPrimaryProps {
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}

function ButtonPrimary({
  onClick = () => {},
  className = '', // Only use it for placement
  children,
}: ButtonPrimaryProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`py-2 px-3 bg-yolk text-white border shadow-sm rounded-md text-center font-normal text-sm ${className}`}
    >
      {children}
    </button>
  );
}

export default ButtonPrimary;
