import React, {useContext, useEffect, useState} from 'react';
import {useLocation, NavLink, useNavigate} from 'react-router-dom';
import {AuthContext} from '../../services/auth/AuthProvider';
import {VIDEOS_ROUTE, PROFILE_ROUTE} from 'app/routes';
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid';

function Navbar(): JSX.Element | null {
  const navigate = useNavigate();

  const {user} = useContext(AuthContext);
  if (!user) return null;

  const profilePic = user.avatarUrl;

  const NavigationItem = ({
    to,
    children,
    target,
  }: {
    to: string;
    children: JSX.Element;
    target?: string;
  }) => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);

    // Check if current location ends with the NavLink's 'to' prop
    useEffect(() => {
      const isUploadPath = location.pathname.includes('/upload');
      if (to === VIDEOS_ROUTE && isUploadPath) {
        setIsActive(true);
      }
      // makes sure a path like kitchen.flavrs.com/upload/profile does not make both Videos and Profile active
      else if (location.pathname.endsWith(to) && !isUploadPath) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }, [location, to]);

    return (
      <div
        className={`flex flex-row justify-center items-center h-16 px-2 border-b-2 ${
          isActive ? 'border-yolk' : 'border-white'
        }`}
      >
        <NavLink
          to={to}
          className={`font-medium text-xs md:text-sm ${
            isActive ? 'text-charcoal' : 'text-light'
          }`}
          target={target}
        >
          {children}
        </NavLink>
      </div>
    );
  };

  return (
    <nav className="flex flex-row justify-between items-center w-full h-16 bg-white drop-shadow-md">
      <div
        className="flex flex-row w-1/4 h-16 cursor-pointer"
        onClick={() => navigate(VIDEOS_ROUTE)}
      >
        <div className="w-1/5 lg:w-2/5"></div>
        <div className="flex flex-col justify-center items-start">
          <img src="/flavrs.svg" alt="flavrs logo" className="w-12 h-2.5" />
          <div className="font-simula text-charcoal font-normal text-xs md:text-sm lg:text-lg">
            Creator Kitchen
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center gap-2 h-16">
        <NavigationItem to={VIDEOS_ROUTE}>
          <>Videos</>
        </NavigationItem>
        <NavigationItem to={PROFILE_ROUTE}>
          <>Profile</>
        </NavigationItem>
        <NavigationItem
          to="https://flavrs.notion.site/d09d27d6b0cc431db030accbd7016a66"
          target="_blank"
        >
          <div className="flex flex-row gap-1">
            <span>FAQ</span>
            <ArrowTopRightOnSquareIcon className="h-4 w-4 fill-light" />
          </div>
        </NavigationItem>
      </div>

      <div className="flex flex-row justify-end items-center w-1/5 h-16">
        <div className="cursor-pointer" onClick={() => navigate(PROFILE_ROUTE)}>
          {profilePic && (
            <img
              src={profilePic}
              alt="Profile"
              className="w-6 md:w-8 h-6 md:h-8 rounded-half object-cover"
            />
          )}
        </div>
        <div className="w-1/2 h-full"></div>
      </div>
    </nav>
  );
}

export default Navbar;
