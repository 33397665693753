import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {
  ParseFreeTextDocument,
  ParsingTextType,
} from '../../services/graphql/apolloTypes';
import Modal from 'react-modal';
import {inputBoxStyle, buttonStyle} from '../utilities/styles';
import ErrorPanel from 'features/utilities/ErrorPanel';

interface ImportFreeTextModalProps {
  header: string;
  description: string;
  placeholder: string;
  parsingTextType: ParsingTextType;
  isOpen: boolean;
  onRequestClose: () => void;
  text: string;
  onParsedText: (parsedText: string[]) => void;
  setText: (text: string) => void;
}

const ImportFreeTextModal: React.FC<ImportFreeTextModalProps> = ({
  header,
  description,
  placeholder,
  parsingTextType,
  isOpen,
  onRequestClose,
  text,
  onParsedText,
  setText,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [parseFreeText] = useMutation(ParseFreeTextDocument);

  const handleImport = async () => {
    try {
      setIsImporting(true);
      const {data: parsedText} = await parseFreeText({
        variables: {type: parsingTextType, text},
      });
      setErrorMessage('');
      onRequestClose();

      if (parsedText) {
        onParsedText(parsedText.parseFreeText);
      }
    } catch (err) {
      setIsImporting(false);
      if (err instanceof Error) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage('Error parsing free text.');
      }
    } finally {
      setIsImporting(false);
    }
  };

  // We can't specify a CSS class for Modal. See https://reactcommunity.org/react-modal/styles/.
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(28, 21, 18, 0.7)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      border: 0,
      shadow:
        '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
      borderRadius: '8px',
      width: '60%',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={customStyles}
    >
      <div
        onClick={onRequestClose}
        className="flex flex-row justify-end items-start mt-6 cursor-pointer"
      >
        <img src="/multiply.svg" alt="multiply logo" />
        <div className="w-6"></div>
      </div>
      <div className="flex flex-row items-start text-charcoal">
        <div className="w-6"></div>
        <img src="/import.svg" alt="import logo" />
        <div className="ml-4">
          <h1 className="text-lg font-medium mb-2">{header}</h1>
          {description.split('\\n').map((str, index) => (
            <p key={index} className="text-truffle text-sm font-normal">
              {str}
            </p>
          ))}
        </div>
        <div className="w-6"></div>
      </div>

      <div className="flex flex-row justify-between items-start mt-6">
        <div className="w-6"></div>
        <textarea
          placeholder={placeholder}
          value={text}
          onChange={e => {
            setText(e.target.value);
            setErrorMessage('');
          }}
          className={`w-full h-88 p-3 ${inputBoxStyle}`}
        />
        <div className="w-6"></div>
      </div>
      <div className="flex justify-end items-center mt-5 mb-6">
        <p
          className={`text-xs font-normal ml-6 ${
            isImporting ? ' text-tomatoshade' : ' text-truffle'
          }`}
        >
          Feel free to close this window while importing is in progress
        </p>
        <button
          onClick={onRequestClose}
          className={`h-10 ml-4 px-3 bg-white font-normal ${buttonStyle} text-truffle`}
        >
          Cancel
        </button>

        <button
          onClick={handleImport}
          disabled={isImporting || text.length === 0}
          className={`h-10 ml-3 px-3 ${buttonStyle} ${
            text.length !== 0 ? 'bg-charcoal text-white' : 'bg-salt text-light'
          }`}
        >
          <div className="flex">
            <img src="/right_arrow.svg" alt="right arrow logo" />
            <span className="ml-2">
              {isImporting ? 'Importing...' : 'Import'}
            </span>
          </div>
        </button>
        <div className="w-6"></div>
      </div>
      <div className="flex justify-center">
        <ErrorPanel error={errorMessage}></ErrorPanel>
      </div>
    </Modal>
  );
};

export default ImportFreeTextModal;
