import React, {useContext, useState, useEffect} from 'react';
import {default as IngredientSection} from './IngredientSection';
import {IngredientsContext} from '../../components/autocomplete/AutocompleteProviders';
import {ParsingTextType, InputMaybe} from '../../services/graphql/apolloTypes';
import ImportFreeTextModal from './ImportFreeTextModal';
import {errorMessageStyle, headerStyle} from 'features/utilities/styles';
import {UploadErrors} from 'features/utilities/errors';
import {InputActionMeta} from 'react-select';
import {ExtendedIngredientSectionInput} from './upload-recipe-types';

type IngredientSectionsProps = {
  ingredientSections: ExtendedIngredientSectionInput[];
  setIngredientSections: (
    ingredientSections: ExtendedIngredientSectionInput[]
  ) => void;
  ingredientFreeText: string;
  setIngredientFreeText: (text: string) => void;
  ingredientsRef: React.RefObject<HTMLButtonElement>;
  uploadErrors: UploadErrors;
  setUploadErrors: React.Dispatch<React.SetStateAction<UploadErrors>>;
};

const IngredientSections: React.FC<IngredientSectionsProps> = ({
  ingredientSections,
  setIngredientSections,
  ingredientFreeText,
  setIngredientFreeText,
  ingredientsRef,
  uploadErrors,
  setUploadErrors,
}) => {
  const {items} = useContext(IngredientsContext);
  const [ingredientOptions, setIngredientOptions] = useState<
    {value: string; label: string}[]
  >([]);
  // Sort alphabetically if they have the same number of words,
  // if not prioritize the one with fewer words
  // ex. if a user searches for sugar, sugar should surface before brown sugar
  useEffect(() => {
    setIngredientOptions(
      items
        .map(ingredient => ({
          value: ingredient.id,
          label: ingredient.displayName,
        }))
        .sort((a, b) => {
          const aWordsCount = a.label.split(' ').length;
          const bWordsCount = b.label.split(' ').length;

          if (aWordsCount !== bWordsCount) {
            return aWordsCount - bWordsCount;
          } else {
            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
          }
        })
    );
  }, [items]);

  // Modal
  const [modalOpen, setModalOpen] = useState(false);

  // Handlers
  const handleIngredientSectionNameChange = (index: number, value: string) => {
    const newIngredientSections = [...ingredientSections];
    newIngredientSections[index].name = value;
    setIngredientSections(newIngredientSections);
  };

  const handleQuantityChange = (
    sectionIndex: number,
    ingredientIndex: number,
    value: string
  ) => {
    const newIngredientSections = [...ingredientSections];
    newIngredientSections[sectionIndex].ingredients[
      ingredientIndex
    ].quantityStr = value;
    setIngredientSections(newIngredientSections);

    // reset specific quantity error
    const errorKey = `quantityStr_${sectionIndex}_${ingredientIndex}`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const handleUnitChange = (
    sectionIndex: number,
    ingredientIndex: number,
    value: string
  ) => {
    const newIngredientSections = [...ingredientSections];
    newIngredientSections[sectionIndex].ingredients[ingredientIndex].unit =
      value;
    setIngredientSections(newIngredientSections);
  };

  const handleIngredientPickerChange = (
    sectionIndex: number,
    ingredientIndex: number,
    selectedOption: {
      value: InputMaybe<string> | undefined;
      label: InputMaybe<string> | undefined;
    } | null
  ) => {
    if (!selectedOption) {
      return;
    }
    // all ingredients from dropdown are in the database
    const newIngredientSections = [...ingredientSections];
    newIngredientSections[sectionIndex].ingredients[
      ingredientIndex
    ].rawIngredientLine = '';
    newIngredientSections[sectionIndex].ingredients[
      ingredientIndex
    ].ingredientId = selectedOption.value;
    // set displayName
    newIngredientSections[sectionIndex].ingredients[
      ingredientIndex
    ].displayName = selectedOption.label || '';

    setIngredientSections(newIngredientSections);

    // reset specific ingredient error
    const errorKey = `ingredient_${sectionIndex}_${ingredientIndex}`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const handleInputChange = (
    value: string,
    action: InputActionMeta,
    sectionIndex: number,
    ingredientIndex: number
  ) => {
    if (action.action === 'input-change') {
      // set rawIngredientLine to input value
      const newIngredientSections = [...ingredientSections];
      newIngredientSections[sectionIndex].ingredients[
        ingredientIndex
      ].ingredientId = '';
      newIngredientSections[sectionIndex].ingredients[
        ingredientIndex
      ].displayName = '';
      newIngredientSections[sectionIndex].ingredients[
        ingredientIndex
      ].rawIngredientLine = value;
      setIngredientSections(newIngredientSections);

      // reset specific ingredient error
      const errorKey = `ingredient_${sectionIndex}_${ingredientIndex}`;
      if (uploadErrors[errorKey]) {
        const newUploadErrors = {...uploadErrors};
        delete newUploadErrors[errorKey];
        setUploadErrors(newUploadErrors);
      }
    }
  };

  const handlePreparationChange = (
    sectionIndex: number,
    ingredientIndex: number,
    value: string
  ) => {
    const newIngredientSections = [...ingredientSections];
    newIngredientSections[sectionIndex].ingredients[
      ingredientIndex
    ].preparation = value;
    setIngredientSections(newIngredientSections);
  };

  const addIngredient = () => {
    const newIngredientSections = [...ingredientSections];
    const sectionIndex = newIngredientSections.length - 1;
    newIngredientSections[sectionIndex].ingredients.push({
      ingredientId: '',
      preparation: '',
      rawIngredientLine: '',
      unit: '',
      displayName: '',
      quantityStr: '',
    });
    setIngredientSections(newIngredientSections);

    const errorKey = `ingredientSection_${sectionIndex}_`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const addIngredientSection = () => {
    setIngredientSections([...ingredientSections, {name: '', ingredients: []}]);
  };

  const deleteIngredientSection = (sectionIndex: number) => {
    const newIngredientSections = [...ingredientSections];
    // move ingredients in section to previous section, set section name to empty string if first section
    if (sectionIndex > 0) {
      newIngredientSections[sectionIndex - 1].ingredients.push(
        ...newIngredientSections[sectionIndex].ingredients
      );
      newIngredientSections.splice(sectionIndex, 1);
    } else {
      newIngredientSections[sectionIndex].name = '';
    }
    setIngredientSections(newIngredientSections);

    const errorKey = `ingredientSection_${sectionIndex}_`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  const deleteIngredient = (sectionIndex: number, ingredientIndex: number) => {
    const newIngredientSections = [...ingredientSections];
    newIngredientSections[sectionIndex].ingredients.splice(ingredientIndex, 1);
    setIngredientSections(newIngredientSections);

    const newUploadErrors = {...uploadErrors};
    // Reset specific ingredient error
    const ingredientErrorKey = `ingredient_${sectionIndex}_${ingredientIndex}`;
    if (newUploadErrors[ingredientErrorKey]) {
      delete newUploadErrors[ingredientErrorKey];
    }

    // Reset specific quantityStr error
    const quantityStrErrorKey = `quantityStr_${sectionIndex}_${ingredientIndex}`;
    if (newUploadErrors[quantityStrErrorKey]) {
      delete newUploadErrors[quantityStrErrorKey];
    }

    // Update the state if any changes
    if (
      Object.keys(newUploadErrors).length !== Object.keys(uploadErrors).length
    ) {
      setUploadErrors(newUploadErrors);
    }
  };

  // Parsing
  const parseIngredientSections = (parsedText: string[]) => {
    const newIngredientSections: ExtendedIngredientSectionInput[] = [];
    let currentSection: ExtendedIngredientSectionInput = {
      name: '',
      ingredients: [],
    };
    parsedText.forEach(line => {
      if (line.startsWith('[') && line.endsWith(']')) {
        if (currentSection.name !== '') {
          newIngredientSections.push(currentSection);
        }
        currentSection = {
          name: line.slice(1, line.length - 1),
          ingredients: [],
        };
      } else {
        // Make sure line has three semicolons
        const splitLine = line.split(';');
        if (splitLine.length !== 4) {
          return;
        }

        // Check if ingredient is in current DB. If so, set ingredientId. Else, set rawIngredientLine
        const ingredientInOptions = items.find(
          option =>
            option.displayName.toLowerCase() === splitLine[2].toLowerCase()
        );
        let ingredientId = '';
        let rawIngredientLine = '';
        if (ingredientInOptions) {
          ingredientId = ingredientInOptions.id;
        } else {
          rawIngredientLine = splitLine[2];
        }

        // Add ingredient to current section
        currentSection.ingredients.push({
          unit: splitLine[1],
          ingredientId: ingredientId,
          rawIngredientLine: rawIngredientLine,
          preparation: splitLine[3],
          displayName: splitLine[2],
          quantityStr: splitLine[0],
        });
      }
    });
    newIngredientSections.push(currentSection);
    setIngredientSections(newIngredientSections);

    // reset ingredient and quantityStr errors
    const newUploadErrors = {...uploadErrors};
    for (const key in newUploadErrors) {
      if (key.startsWith('ingredient_') || key.startsWith('quantityStr_')) {
        delete newUploadErrors[key];
      }
    }
    setUploadErrors(newUploadErrors);
  };

  const addIngredientToStartOfSection = (sectionIndex: number) => {
    const newInstructionSections = [...ingredientSections];
    const emptyIngredient = {
      ingredientId: '',
      preparation: '',
      rawIngredientLine: '',
      unit: '',
      displayName: '',
      quantityStr: '1',
    };
    newInstructionSections[sectionIndex].ingredients = [
      emptyIngredient,
      ...newInstructionSections[sectionIndex].ingredients,
    ];
    setIngredientSections(newInstructionSections);

    const errorKey = `ingredientSection_${sectionIndex}_`;
    if (uploadErrors[errorKey]) {
      const newUploadErrors = {...uploadErrors};
      delete newUploadErrors[errorKey];
      setUploadErrors(newUploadErrors);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pb-2">
        <h3 className={headerStyle}>Ingredients</h3>
        <button
          ref={ingredientsRef}
          type="button"
          onClick={() => setModalOpen(true)}
          className="text-persimmon focus:outline-none focus:ring-2 focus:ring-persimmon rounded-md p-2"
        >
          <div className="flex space-x-1">
            <img src="/upload/importfreetexticon.svg" alt="import icon" />
            <p>Import from plain text</p>
          </div>
        </button>
      </div>
      <p className="font-light">
        Use the preview box on the left to double check your ingredients are
        inputted accurately.
      </p>
      <p className="font-light pb-2">
        Tip: use the keyboard tab key to go to the next input box, shift + tab
        to go to the previous one
      </p>
      {Object.keys(uploadErrors).map((key, index) => {
        if (key.startsWith('quantityStr') || key.startsWith('ingredient')) {
          return (
            <p key={index} className={errorMessageStyle}>
              {uploadErrors[key]}
            </p>
          );
        }
        return null;
      })}
      <ImportFreeTextModal
        header="Import Ingredients"
        description="Feel free to copy and paste directly from your recipe, don't worry about making any changes."
        placeholder="For the sauce&#10;1 chopped white onion&#10;3 cloves minced garlic&#10;1 chopped bell pepper&#10;For the lasagna&#10;2 lbs round beef"
        parsingTextType={ParsingTextType.Ingredients}
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        text={ingredientFreeText}
        onParsedText={parseIngredientSections}
        setText={setIngredientFreeText}
      />
      {ingredientSections.map(
        (
          ingredientSection: ExtendedIngredientSectionInput,
          sectionIndex: number
        ) => (
          <IngredientSection
            key={`ingredient-section-${sectionIndex}`}
            ingredientOptions={ingredientOptions}
            sectionIndex={sectionIndex}
            ingredientSection={ingredientSection}
            handleIngredientSectionNameChange={
              handleIngredientSectionNameChange
            }
            handleQuantityChange={handleQuantityChange}
            handleUnitChange={handleUnitChange}
            handleIngredientPickerChange={handleIngredientPickerChange}
            handleInputChange={handleInputChange}
            handlePreparationChange={handlePreparationChange}
            deleteIngredientSection={deleteIngredientSection}
            deleteIngredient={deleteIngredient}
            addIngredientToStartOfSection={addIngredientToStartOfSection}
            uploadErrors={Object.keys(uploadErrors)
              .filter(
                errorKey =>
                  errorKey.includes(`_${sectionIndex}_`) &&
                  (errorKey.startsWith('ingredient') ||
                    errorKey.startsWith('quantityStr') ||
                    errorKey.startsWith('ingredientSection'))
              )
              .reduce((newUploadErrors, key) => {
                return {
                  ...newUploadErrors,
                  [key]: uploadErrors[key],
                };
              }, {})} // only include errors for this section
          />
        )
      )}
      <button
        type="button"
        onClick={addIngredient}
        className="border border-dashed border-taro text-light font-normal py-2 rounded-md"
      >
        <div className="flex justify-center space-x-1" title="Add ingredient">
          <img src="/addrowicon.svg" alt="add row icon"></img>
          <p>Add ingredient</p>
        </div>
      </button>
      <button
        type="button"
        onClick={addIngredientSection}
        className="text-truffle font-medium mt-4"
      >
        <div className="flex justify-center space-x-1">
          <img
            src="/addheadericon.svg"
            alt="add header icon"
            title="Add section header"
          ></img>
          <p>Add section header</p>
        </div>
      </button>
    </>
  );
};

export default IngredientSections;
